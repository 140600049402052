import React from 'react';
import { RequestReviewStepTransitionProps } from './types';
import { useAuth } from 'auth';
import { AuthorTypeTag } from 'components/tags/author-type-tag';
import { ProblemType, ReviewReason } from 'graphql/types';
import { ProblemTypeTag } from 'components/tags/problem-type';
import { Customer } from 'pages/customer/types';
import { Dropdown } from 'components/dropdown';
import { reasonOptions } from 'utils/dropdown-options';
import { useForm } from 'react-hook-form';
import { MarkdownEditor } from 'components/markdown-editor';
import { Button } from 'components/button';
import { requiredValidation } from 'utils/form-validation';

type AdminNotesStepProps = RequestReviewStepTransitionProps & {
  skipQuiz: boolean;
  adminNotes?: string | null;
  setAdminNotes: (notes: string) => void;
  reason?: ReviewReason | undefined;
  setReason: (reason: ReviewReason) => void;
  problemType: ProblemType;
  customer: Customer;
};

export const AdminNotesStep: React.FC<AdminNotesStepProps> = ({
  previousStep,
  nextStep,
  customer,
  skipQuiz,
  adminNotes,
  setAdminNotes,
  reason,
  setReason,
  problemType,
}) => {
  const { user } = useAuth();

  const { register, watch, handleSubmit, setValue, control, errors } = useForm<{
    notes: string;
    reason: ReviewReason;
  }>({
    defaultValues: { notes: adminNotes ?? '', reason },
  });
  const watchedNotes = watch().notes;

  const submit = handleSubmit(({ notes, reason }) => {
    setReason(reason);
    if (notes) {
      setAdminNotes(notes);
      nextStep();
    } else if (!skipQuiz) {
      nextStep();
    } else {
      throw new Error('Notes are required');
    }
  });

  const onCancelClick = (): void => {
    setValue('notes', '');
    previousStep();
  };

  return (
    <form onSubmit={submit}>
      <div>
        <h2 className="text-lg font-semibold mb-2">
          Add review reason and note
        </h2>
      </div>
      <div className="w-1/4 mb-2">
        <Dropdown
          label="Review reason*"
          name="reason"
          options={reasonOptions}
          control={control}
          errorMessage={errors.reason?.message}
          rules={{ required: 'Review reason is required' }}
        />
      </div>
      <div className="flex flex-row w-full items-start space-x-5">
        <div className="flex text-sm gap-4">
          <div className="space-y-1">
            <p>
              <strong>Author: </strong>
              {user?.fullName}
            </p>
            <p>
              <strong>Author type: </strong>
              <AuthorTypeTag author={user} />
            </p>
          </div>
          <div className="space-y-1">
            <p>
              <strong>Patient: </strong>
              {customer.fullName}
            </p>
            <p>
              <strong>Problem type: </strong>
              <ProblemTypeTag problemType={problemType} />
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col space-y-4 max-h-full">
        <div className="text-sm space-y-3">
          <p>
            This is required if the patient can skip the quiz. This is for
            internal use only. Other admins, practitioners and coaches will be
            able to see these notes.
          </p>
        </div>
        <MarkdownEditor
          ref={skipQuiz ? register(requiredValidation('notes')) : register}
          name="notes"
          markdown={watchedNotes}
        />
        <div className="flex flex-row justify-end gap-4">
          <div>
            <Button
              fullWidth
              type="button"
              variant="outline"
              onClick={onCancelClick}
            >
              Back
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              type="submit"
              disabled={skipQuiz && !watchedNotes}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
