import React from 'react';
import { RequestReviewStepTransitionProps } from './types';
import { RequestReviewLayout } from './request-review-layout';
import { MarkdownEditor } from 'components/markdown-editor';
import { useForm } from 'react-hook-form';
import { requiredValidation } from 'utils/form-validation';
import { Customer } from 'pages/customer/types';
import { useAuth } from 'auth';
import { AuthorTypeTag } from 'components/tags/author-type-tag';
import { ProblemTypeTag } from 'components/tags/problem-type';
import { ProblemType, ReviewReason } from 'graphql/types';
import { Dropdown } from 'components/dropdown';
import { reasonOptions } from 'utils/dropdown-options';

type AdminNotesStepProps = RequestReviewStepTransitionProps & {
  skipQuiz: boolean;
  notes: string;
  setAdminNotes: (adminNotes: string) => void;
  reason: ReviewReason | undefined;
  setReason: (reason: ReviewReason) => void;
  customer: Customer;
  problemType: ProblemType;
};

export const AdminNotesStep: React.FC<AdminNotesStepProps> = ({
  previousStep,
  nextStep,
  skipQuiz,
  notes,
  setAdminNotes,
  reason,
  setReason,
  customer,
  problemType,
}) => {
  const { user } = useAuth();
  const { register, handleSubmit, watch, errors, control } = useForm<{
    adminNotes: string;
    reason: ReviewReason;
  }>({
    defaultValues: {
      reason,
      adminNotes: notes,
    },
  });

  const adminNotes = watch().adminNotes;

  return (
    <RequestReviewLayout
      title="Add review reason and note"
      previous={previousStep}
      onSubmit={handleSubmit(({ adminNotes, reason }) => {
        setAdminNotes(adminNotes);
        setReason(reason);
        nextStep();
      })}
    >
      <div className="w-1/4">
        <Dropdown
          label="Review reason*"
          name="reason"
          options={reasonOptions}
          control={control}
          errorMessage={errors.reason?.message}
          rules={{ required: 'Review reason is required' }}
        />
      </div>
      <div className="flex flex-row w-full items-start space-x-5">
        <div className="flex text-sm gap-4">
          <div className="space-y-1">
            <p>
              <strong>Author: </strong>
              {user?.fullName}
            </p>
            <p>
              <strong>Author type: </strong>
              <AuthorTypeTag author={user} />
            </p>
          </div>
          <div className="space-y-1">
            <p>
              <strong>Patient: </strong>
              {customer.fullName}
            </p>
            <p>
              <strong>Problem type: </strong>
              <ProblemTypeTag problemType={problemType} />
            </p>
          </div>
        </div>
      </div>
      <div className="text-sm space-y-3">
        <p>
          This is required if the patient can skip the quiz. This is for
          internal use only. Other admins, practitioners and coaches will be
          able to see these notes.
        </p>
      </div>
      <MarkdownEditor
        ref={skipQuiz ? register(requiredValidation('adminNotes')) : register}
        name="adminNotes"
        markdown={adminNotes}
        errorMessage={errors.adminNotes?.message}
      />
    </RequestReviewLayout>
  );
};
