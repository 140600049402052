import React from 'react';
import { Button } from '../../components/button';
import { Variant } from './variant';
import { ProductType, VariantFieldsFragment } from 'graphql/types';

export const VariantSection = ({
  orderLineItemsCount,
  productId,
  productType,
  refetch,
  variants,
}: {
  orderLineItemsCount: number | undefined;
  productId: string;
  productType: ProductType;
  refetch: () => Promise<void>;
  variants: VariantFieldsFragment[];
}): React.ReactElement => {
  const [creatingVariant, setCreatingVariant] = React.useState(false);

  return (
    <>
      <ul className="space-y-5">
        {variants.map((variant) => (
          <Variant
            key={variant.id}
            variant={variant}
            orderLineItemsCount={orderLineItemsCount ?? 0}
            onDelete={refetch}
            onCreate={refetch}
            mode={{
              operation: 'update',
            }}
          />
        ))}

        {creatingVariant && (
          <Variant
            mode={{
              operation: 'create',
              onCreateCancel: () => setCreatingVariant(false),
            }}
            onCreate={refetch}
            onDelete={refetch}
            orderLineItemsCount={0}
            variant={{
              id: '',
              name: '',
              price: 0,
              public: false,
              isRefill: false,
              product: {
                id: productId,
                productType,
              },
            }}
          />
        )}
      </ul>

      <Button
        fullWidth
        disabled={creatingVariant}
        onClick={() => setCreatingVariant(true)}
      >
        Add variant
      </Button>
    </>
  );
};
