import { gql, useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  positiveIntegerValidation,
  requiredValidation,
} from 'utils/form-validation';
import {
  Product,
  ProductUpdateInput,
  ProductWhereUniqueInput,
  ProblemType,
  Plan,
  FileCreateWithoutPhotoOfInput,
  FileCreateWithoutCmiOfInput,
  Faq,
  ProductUsage,
  Maybe,
  ErxMedicine,
  ProductType,
} from 'graphql/types';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Input } from 'components/input';
import { TextArea } from 'components/text-area';
import { Loading } from 'components/loading';
import { Dropdown, Option } from 'components/dropdown';
import { Button } from 'components/button';
import { ControlledFileUpload } from 'components/file-upload';
import { MarkdownEditor } from 'components/markdown-editor';
import {
  problemTypeOptions,
  productUsageOptions,
} from 'utils/dropdown-options';
import { setIfExists } from 'utils/prisma';
import { useAlert } from 'alert';
import { useNotifications } from 'notifications';
import { Checkbox } from '../../components/checkbox';
import { RxMedicationsSection } from './medications/medications-section';
import { useErxMedicineCrud } from '../../utils/use-erx-medicine-crud';
import { removeBlankAttributesInObject } from '../../utils/remove-blank-attributes';
import { ShopifySection } from './shopify/shopify-section';
import { VariantSection } from './variant-section';
import { ProductTypeSelect } from 'components/product-type-select';
import { DeliveryBufferSelect } from 'components/delivery-buffer-select';
import { config } from 'config';
import { ConfirmModal } from 'components/confirm-modal';
import { routes } from 'utils/routes';
import { Variant } from './variant';

const productQuery = gql`
  query Product($id: String!) {
    product(where: { id: $id }) {
      id
      name
      description
      friendlyName
      shortDescription
      safetyInformation
      deliveryInformation
      slug
      problemTypes
      productType
      stripePlanId
      shopifyProductId
      usage
      units
      priority
      deliveryBufferInDays
      discontinuedAt
      preventProcessing
      photo {
        id
        url
        filename
        mimetype
      }
      cmi {
        id
        url
        filename
        mimetype
      }

      plan {
        id
        name
      }

      variants(orderBy: { createdAt: asc }) {
        ...VariantFields
      }

      faqs(orderBy: { createdAt: asc }) {
        id
        title
        markdown
        videoEmbedUrl
      }

      erxMedicines {
        id
        productName
        tradeName
        genericName
        mimsId
        instructions
        quantity
        routeAdministration
        itemStrength
        itemForm
      }
    }
    plans {
      id
      name
      amount
    }
    orderLineItemsCount(where: { variant: { productId: { equals: $id } } })
  }

  ${Variant.variantFragment}
`;

const updateProductMutation = gql`
  mutation updateProduct($id: String!, $data: ProductUpdateInput!) {
    updateOneProduct(where: { id: $id }, data: $data) {
      id
    }
  }
`;

const discontinueProductMutation = gql`
  mutation discontinueProduct($productId: String!) {
    discontinueProduct(productId: $productId) {
      product {
        id
        discontinuedAt
      }
    }
  }
`;

const activateDiscontinuedProductMutation = gql`
  mutation activateDiscontinuedProduct($productId: String!) {
    activateDiscontinuedProduct(productId: $productId) {
      product {
        id
        discontinuedAt
      }
    }
  }
`;

const gridColumnStyles = 'grid grid-cols-2 gap-x-5';

export type Fields = {
  name: string;
  friendlyName: string;
  description: string;
  shortDescription: string;
  deliveryInformation: string;
  photo: string;
  productType: ProductType;
  problemTypes: ProblemType[];
  usage: ProductUsage;
  units: Maybe<number>;
  cmiUrl: string;
  safetyInformation: string;
  planId: string;
  faqs: Partial<Faq>[];
  shopifyProductId: string;
  pinned: boolean;
  erxMedicines: ErxMedicine[];
  deliveryBufferInDays: string;
  preventProcessing: boolean;
};

const ProductPage = (): React.ReactElement => {
  const showNotification = useNotifications();
  const { productId } = useParams<{ productId: string }>();
  const showAlert = useAlert();
  const { updateErxMedicine } = useErxMedicineCrud();

  const { data, loading, refetch } = useQuery<
    {
      product: Product;
      plans: Plan[];
      orderLineItemsCount: number;
    },
    ProductWhereUniqueInput
  >(productQuery, { variables: { id: productId } });

  const [discontinueProduct, { loading: discontinueLoading }] = useMutation(
    discontinueProductMutation,
    {
      variables: { productId },
      onCompleted: () => {
        showNotification({
          message: `Product discontinued`,
          type: 'success',
        });
      },
    },
  );

  const [
    activateDiscontinuedProduct,
    { loading: activateDiscontinuedLoading },
  ] = useMutation(activateDiscontinuedProductMutation, {
    variables: { productId },
    onCompleted: () => {
      showNotification({
        message: `Product reactivated`,
        type: 'success',
      });
    },
  });

  const {
    name,
    friendlyName,
    description,
    shortDescription,
    safetyInformation,
    deliveryInformation,
    usage,
    units,
    photo,
    cmi,
    plan,
    faqs,
    erxMedicines,
    variants,
    shopifyProductId,
    priority,
    productType,
    problemTypes,
    deliveryBufferInDays,
    discontinuedAt,
    preventProcessing,
  } = data?.product ?? {};

  const {
    register,
    reset,
    setValue,
    getValues,
    control,
    handleSubmit,
    watch,
    formState: { dirtyFields },
    errors,
    trigger,
  } = useForm<Fields>();

  const planOptions: Option[] =
    data?.plans?.map((plan) => ({
      label: plan.name,
      value: plan.id,
    })) ?? [];

  const watchedUsage = watch().usage;

  const [updateProduct, { loading: processingUpdateProduct }] = useMutation<{
    updateOneProduct: Product;
  }>(updateProductMutation);

  React.useEffect(() => {
    reset({
      pinned: !!priority,
      name: name ?? '',
      friendlyName: friendlyName ?? '',
      description: description ?? '',
      shortDescription: shortDescription ?? '',
      deliveryInformation: deliveryInformation ?? '',
      photo: JSON.stringify({
        url: photo?.url,
        filename: photo?.filename,
        mimetype: photo?.mimetype,
      }),
      usage,
      units,
      cmiUrl: JSON.stringify({
        url: cmi?.url,
        filename: cmi?.filename,
        mimetype: cmi?.mimetype,
      }),
      safetyInformation: safetyInformation ?? '',
      problemTypes,
      planId: plan?.id ?? '',
      faqs,
      shopifyProductId: shopifyProductId ?? '',
      erxMedicines,
      productType,
      preventProcessing,
      deliveryBufferInDays:
        deliveryBufferInDays === null || deliveryBufferInDays === undefined
          ? undefined
          : `${deliveryBufferInDays}`,
    });
  }, [
    friendlyName,
    description,
    shortDescription,
    deliveryInformation,
    photo,
    cmi?.filename,
    cmi?.mimetype,
    cmi?.url,
    name,
    usage,
    units,
    safetyInformation,
    plan?.id,
    faqs,
    shopifyProductId,
    variants,
    reset,
    priority,
    erxMedicines,
    problemTypes,
    productType,
    deliveryBufferInDays,
    preventProcessing,
  ]);

  if (loading) {
    return <Loading />;
  }

  const handleRefetch = async (): Promise<void> => {
    await refetch();
  };

  const createFaq = async (): Promise<void> => {
    const createFaqData: ProductUpdateInput = {
      faqs: {
        create: [
          {
            title: null,
            markdown: null,
            videoEmbedUrl: null,
          },
        ],
      },
    };

    await createOrDeleteOnProduct('FAQ', 'create', createFaqData);
  };

  const deleteFaq = async (faqId: string): Promise<void> => {
    const deleteFaqData: ProductUpdateInput = {
      faqs: {
        delete: [
          {
            id: faqId,
          },
        ],
      },
    };

    await createOrDeleteOnProduct('FAQ', 'delete', deleteFaqData);
  };

  const proceedWithUpdate = async (): Promise<boolean> => {
    if (Object.keys(dirtyFields).length) {
      const result = await showAlert({
        content:
          'You may have unsaved changes, are you sure you want to proceed?',
      });

      return result;
    }

    return true;
  };

  const createOrDeleteOnProduct = async (
    item: 'FAQ' | 'Variant',
    action: 'create' | 'delete',
    data: ProductUpdateInput,
  ): Promise<void> => {
    if (!(await proceedWithUpdate())) {
      return;
    }

    await updateProduct({
      variables: {
        id: productId,
        data,
      },
    });

    showNotification({
      message: `${item} ${action}d`,
      type: 'success',
    });

    refetch();
  };
  const handleSaveProduct = handleSubmit(async (formData): Promise<void> => {
    if ((data?.orderLineItemsCount ?? 0) > 0 && dirtyFields.planId) {
      showNotification({
        type: 'error',
        message: `You can not update the plan since it is being used!`,
      });
      return;
    }
    const productData: ProductUpdateInput = {
      // OTC product data
      ...(dirtyFields.name && { name: setIfExists(formData.name) }),
      ...(dirtyFields.problemTypes && {
        problemTypes: setIfExists(formData.problemTypes),
      }),
      ...(dirtyFields.usage && {
        usage: setIfExists(formData.usage),
      }),
      ...(dirtyFields.pinned && {
        priority: setIfExists(formData.pinned ? 1 : 0),
      }),
      ...(dirtyFields.units && {
        units: setIfExists(
          formData.units ? parseInt(formData.units + '', 10) : formData.units,
        ),
      }),
      ...(dirtyFields.deliveryBufferInDays && {
        deliveryBufferInDays: setIfExists(
          formData.deliveryBufferInDays
            ? parseInt(formData.deliveryBufferInDays, 10)
            : undefined,
        ),
      }),
      // Rx product data
      ...(dirtyFields.friendlyName && {
        friendlyName: setIfExists(formData.friendlyName),
      }),
      ...(dirtyFields.shortDescription && {
        shortDescription: setIfExists(formData.shortDescription),
      }),
      ...(dirtyFields.safetyInformation && {
        safetyInformation: setIfExists(formData.safetyInformation),
      }),
      ...(dirtyFields.deliveryInformation && {
        deliveryInformation: setIfExists(formData.deliveryInformation),
      }),
      ...(dirtyFields.planId && {
        plan: {
          connect: {
            id: formData.planId,
          },
        },
      }),

      // Shared product data
      ...(dirtyFields.description && {
        description: setIfExists(formData.description),
      }), // OTC: description, Rx: Clinician snippet
      ...(dirtyFields.shopifyProductId && {
        shopifyProductId: setIfExists(formData.shopifyProductId),
      }),
      ...(dirtyFields.preventProcessing && {
        preventProcessing: setIfExists(formData.preventProcessing),
      }),
    };
    if (dirtyFields.cmiUrl) {
      const {
        url: cmiUrl,
        filename: cmiFilename,
        mimetype: cmiMimetype,
      } = JSON.parse(formData.cmiUrl) as FileCreateWithoutCmiOfInput;

      productData.cmi = {
        create: {
          url: cmiUrl,
          filename: cmiFilename,
          mimetype: cmiMimetype,
        },
      };
    }

    if (dirtyFields.photo) {
      const {
        url: photoUrl,
        filename: photoFilename,
        mimetype: photoMimetype,
      } = JSON.parse(formData.photo) as FileCreateWithoutPhotoOfInput;

      productData.photo = {
        create: {
          url: photoUrl,
          filename: photoFilename,
          mimetype: photoMimetype,
        },
      };
    }

    if (dirtyFields.faqs?.length) {
      productData.faqs = {
        updateMany: formData.faqs.map((faq, i) => ({
          where: {
            id: {
              equals: faq.id,
            },
          },
          data: {
            ...(dirtyFields.faqs?.[i]?.title && {
              title: setIfExists(faq.title),
            }),
            ...(dirtyFields.faqs?.[i]?.videoEmbedUrl && {
              videoEmbedUrl: setIfExists(faq.videoEmbedUrl),
            }),
            ...(dirtyFields.faqs?.[i]?.markdown && {
              markdown: setIfExists(faq.markdown),
            }),
          },
        })),
      };
    }

    // Update Erx Medicines - it doesn't create them.
    if (dirtyFields.erxMedicines?.length) {
      const filteredMedicines = formData.erxMedicines?.filter(
        (med) =>
          med.id &&
          med.productName &&
          med.tradeName &&
          med.itemForm &&
          med.instructions &&
          med.quantity,
      );
      for (const medicine of filteredMedicines) {
        await updateErxMedicine({
          ...removeBlankAttributesInObject(medicine),
        });
      }
    }

    await updateProduct({
      variables: {
        id: productId,
        data: productData,
      },
    });

    await refetch();

    showNotification({
      type: 'success',
      message: 'Product updated',
    });
  });

  return (
    <div>
      <div className="flex justify-end mb-8 gap-4">
        {productType === 'RX' && (
          <DiscontinueSection
            isDiscontinued={!!discontinuedAt}
            isLoading={discontinueLoading || activateDiscontinuedLoading}
            onDiscontinue={discontinueProduct}
            onReactivate={activateDiscontinuedProduct}
          />
        )}
        <Button
          type="button"
          loading={processingUpdateProduct}
          onClick={() => {
            handleSaveProduct();
          }}
        >
          Save changes
        </Button>
      </div>
      <div className="flex flex-row gap-4">
        <form className="w-2/3">
          {productType === 'RX' ? (
            <div className="space-y-8">
              <RxCustomerFacingInfo register={register} control={control} />
              <div className="mb-8">
                {(() => {
                  if (productType === 'RX') {
                    return (
                      <RxProductInfo
                        planOptions={planOptions}
                        usage={watchedUsage}
                        register={register}
                        control={control}
                        errors={errors}
                        watch={watch}
                      />
                    );
                  }

                  if (productType === 'ACCESSORY') {
                    return (
                      <AccessoryProductInfo
                        register={register}
                        control={control}
                      />
                    );
                  }

                  return (
                    <DefaultProductInfo register={register} control={control} />
                  );
                })()}
              </div>
              <div>
                <div className="space-y-5 mb-8">
                  {config.fulfilmentVendors.includes('shopify') && (
                    <ShopifySection register={register} />
                  )}
                  <p>
                    Requires refrigeration is configured via&nbsp;
                    <a
                      href="https://app.launchdarkly.com/projects/default/flags/cfg_variant_ids_requiring_refrigeration/targeting?env=production&selected-env=production"
                      className="underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      this LaunchDarkly flag
                    </a>
                    .
                  </p>
                </div>
              </div>
              <RxMedicationsSection
                register={register}
                control={control}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                productId={productId}
                refetch={handleRefetch}
                trigger={trigger}
              />
              <SafetyInfo register={register} control={control} watch={watch} />
              <FaqSection
                createFaq={createFaq}
                deleteFaq={deleteFaq}
                faqs={faqs}
                register={register}
                watch={watch}
                loading={processingUpdateProduct}
              />
            </div>
          ) : (
            <OtcCustomerFacingInfo
              register={register}
              control={control}
              errors={errors}
            />
          )}
        </form>
        <div className="w-1/3 space-y-8 border-l border-gray-200 pl-4">
          <h2 className="heading-md">Variants</h2>
          {productType && (
            <VariantSection
              refetch={handleRefetch}
              productId={productId}
              productType={productType}
              orderLineItemsCount={data?.orderLineItemsCount}
              variants={variants ?? []}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const RxCustomerFacingInfo = ({
  register,
  control,
}: {
  register: ReturnType<typeof useForm>['register'];
  control: ReturnType<typeof useForm>['control'];
}): React.ReactElement => {
  return (
    <div className="space-y-5">
      <h2 className="heading-md">Patient facing info</h2>
      <div className={gridColumnStyles}>
        <div className="space-y-5">
          <div>
            <Input label="Friendly name" name="friendlyName" ref={register} />
          </div>
          <div>
            <TextArea
              rows={4}
              placeholder=""
              label="Short description"
              name="shortDescription"
              ref={register}
            />
          </div>
          <div>
            <TextArea
              rows={4}
              placeholder=""
              label="Delivery Information"
              name="deliveryInformation"
              ref={register}
            />
          </div>
        </div>
        <div>
          <ControlledFileUpload
            name="photo"
            label="Product photo"
            control={control}
          />
        </div>
      </div>
      <div className={gridColumnStyles}>
        <div>
          <TextArea
            rows={6}
            placeholder=""
            label="Practitioner snippet"
            name="description"
            ref={register}
          />
        </div>
      </div>
    </div>
  );
};

const OtcCustomerFacingInfo = ({
  register,
  control,
  errors,
}: {
  register: ReturnType<typeof useForm>['register'];
  control: ReturnType<typeof useForm>['control'];
  errors: ReturnType<typeof useForm>['errors'];
}): React.ReactElement => {
  return (
    <div className="space-y-5">
      <h2 className="heading-md">Patient facing info</h2>
      <div className={gridColumnStyles}>
        <div className="space-y-5">
          <div>
            <Input
              label="Name"
              name="name"
              ref={register(requiredValidation('Name'))}
              errorMessage={errors?.name?.message}
            />
          </div>
          <div>
            <Input label="Friendly name" name="friendlyName" ref={register} />
          </div>
          <div>
            <TextArea
              rows={8}
              placeholder=""
              label="Description"
              name="description"
              ref={register}
            />
          </div>
        </div>
        <div>
          <ControlledFileUpload
            name="photo"
            label="Product photo"
            control={control}
          />
        </div>
      </div>
    </div>
  );
};

const RxProductInfo = ({
  planOptions,
  usage,
  register,
  control,
  errors,
  watch,
}: {
  planOptions: Option[];
  usage: ProductUsage;
  register: ReturnType<typeof useForm>['register'];
  control: ReturnType<typeof useForm>['control'];
  errors: ReturnType<typeof useForm>['errors'];
  watch: ReturnType<typeof useForm>['watch'];
}): React.ReactElement => {
  const planId = watch('planId');

  return (
    <>
      <h2 className="heading-md mb-5">Product info</h2>
      <div className="space-y-5">
        <div>
          <Input
            label="Product name"
            name="name"
            ref={register(requiredValidation('Product name'))}
            errorMessage={errors?.name?.message}
          />
        </div>
        <div>
          <ProductTypeSelect
            label="Product Type"
            name="productType"
            control={control}
            disabled
          />
        </div>
        <div>
          <Dropdown
            label="Problem types"
            name="problemTypes"
            control={control}
            options={problemTypeOptions}
            isMulti
          />
        </div>
        {config.enableDeliveryBufferInProduct && (
          <div>
            <DeliveryBufferSelect
              label="Refill Delivery Buffer (days)"
              name="deliveryBufferInDays"
              control={control}
            />
          </div>
        )}
        <div>
          <Dropdown
            label="Usage"
            name="usage"
            control={control}
            options={productUsageOptions}
          />
        </div>
        {usage === 'OnDemand' && (
          <div>
            <Input
              type="text"
              label="Units"
              name="units"
              ref={register({
                ...requiredValidation('Units'),
                ...positiveIntegerValidation('units'),
              })}
              errorMessage={errors?.units?.message}
            />
          </div>
        )}
        <div className="flex flex-col gap-2">
          <Dropdown
            label="Plan"
            name="planId"
            control={control}
            options={planOptions}
          />
          {planId && (
            <Link to={`${routes.plans}/${planId}`}>
              <Button fullWidth size="small" variant="outline">
                View
              </Button>
            </Link>
          )}
        </div>
        <div>
          <Checkbox
            ref={register()}
            name="preventProcessing"
            label="Prevent Orders"
          />
        </div>
      </div>
    </>
  );
};

const AccessoryProductInfo = ({
  register,
  control,
}: {
  register: ReturnType<typeof useForm>['register'];
  control: ReturnType<typeof useForm>['control'];
}): React.ReactElement => {
  return (
    <>
      <h2 className="heading-md mb-5">Product info</h2>
      <div className="space-y-5">
        <div>
          <ProductTypeSelect
            label="Product Type"
            name="productType"
            control={control}
            disabled
          />
        </div>
        <div>
          <Checkbox
            ref={register()}
            label="Prevent Orders"
            name="preventProcessing"
          />
        </div>
      </div>
    </>
  );
};

const DefaultProductInfo = ({
  control,
  register,
}: {
  control: ReturnType<typeof useForm>['control'];
  register: ReturnType<typeof useForm>['register'];
}): React.ReactElement => {
  return (
    <>
      <h2 className="heading-md mb-5">Product info</h2>
      <div className="space-y-5">
        <div>
          <ProductTypeSelect
            label="Product Type"
            name="productType"
            control={control}
            disabled
          />
        </div>
        <div>
          <Dropdown
            label="Problem types"
            name="problemTypes"
            control={control}
            options={problemTypeOptions}
            isMulti
          />
        </div>
        <div className="flex gap-4">
          <Checkbox name="pinned" ref={register()} label="Pinned" />
          <Checkbox
            ref={register()}
            name="preventProcessing"
            label="Prevent Orders"
          />
        </div>
      </div>
    </>
  );
};

const SafetyInfo = ({
  register,
  control,
  watch,
}: {
  register: ReturnType<typeof useForm>['register'];
  control: ReturnType<typeof useForm>['control'];
  watch: ReturnType<typeof useForm>['watch'];
}): React.ReactElement => {
  const watchedSafetyInfo = watch().safetyInformation;

  return (
    <div>
      <h2 className="heading-md mb-5">Safety Information</h2>
      <div className="space-y-5">
        <div className={gridColumnStyles}>
          <ControlledFileUpload
            label="CMI link"
            control={control}
            name="cmiUrl"
            type="pdf"
          />
        </div>
        <MarkdownEditor
          ref={register}
          name="safetyInformation"
          markdown={watchedSafetyInfo}
        />
      </div>
    </div>
  );
};
const FaqSection = ({
  faqs,
  register,
  watch,
  createFaq,
  deleteFaq,
  loading,
}: {
  faqs: Faq[] | undefined;
  register: ReturnType<typeof useForm>['register'];
  watch: ReturnType<typeof useForm>['watch'];
  createFaq: () => Promise<void>;
  deleteFaq: (faqId: string) => Promise<void>;
  loading: boolean;
}): React.ReactElement => {
  const showAlert = useAlert();

  return (
    <div>
      <h2 className="heading-md mb-5">FAQs</h2>
      <ul className="space-y-8">
        {faqs?.map((faq, i) => (
          <li key={faq.id} className="space-y-5">
            <input
              name={`faqs[${i}].id`}
              value={faq.id}
              className="hidden"
              ref={register}
              readOnly
            />
            <div className={gridColumnStyles}>
              <div>
                <Input label="Title" ref={register} name={`faqs[${i}].title`} />
              </div>
              <div>
                <Input
                  label="Video embed URL"
                  ref={register}
                  name={`faqs[${i}].videoEmbedUrl`}
                />
              </div>
            </div>
            <MarkdownEditor
              ref={register}
              name={`faqs[${i}].markdown`}
              markdown={watch().faqs?.[i]?.markdown}
            />
            <div className="flex justify-end">
              <div className="w-auto">
                <Button
                  fullWidth
                  onClick={async (): Promise<void> => {
                    const result = await showAlert({
                      content: `Are you sure you want to delete this FAQ?`,
                    });
                    if (result) {
                      await deleteFaq(faq.id);
                    }
                  }}
                  disabled={loading}
                  color="danger"
                  variant="outline"
                >
                  Delete
                </Button>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="flex justify-end mt-8">
        <div className="w-auto">
          <Button fullWidth onClick={createFaq} disabled={loading}>
            Add FAQ
          </Button>
        </div>
      </div>
    </div>
  );
};

const DiscontinueSection = ({
  isDiscontinued,
  isLoading,
  onDiscontinue,
  onReactivate,
}: {
  isDiscontinued: boolean;
  isLoading: boolean;
  onDiscontinue: () => void;
  onReactivate: () => void;
}): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button
        color={isDiscontinued ? 'success' : 'danger'}
        loading={isLoading}
        variant="outline"
        onClick={() => {
          setShowModal(true);
        }}
      >
        {isDiscontinued ? 'Reactivate' : 'Discontinue'}
      </Button>
      <ConfirmModal
        show={showModal}
        onConfirm={(): void => {
          if (isDiscontinued) {
            onReactivate();
          } else {
            onDiscontinue();
          }
          setShowModal(false);
        }}
        onClose={(): void => {
          setShowModal(false);
        }}
      >
        {isDiscontinued ? (
          <div className="font-size whitespace-pre-line">
            Reactivating this discontinued product will allow this product to be
            prescribed to new patients. Are you sure you want to reactivate?
          </div>
        ) : (
          <div className="font-size whitespace-pre-line">
            Discontinuing will disable this product from being prescribed to new
            patients. Existing patients can continue using this product. Are you
            sure you want to discontinue?
          </div>
        )}
      </ConfirmModal>
    </>
  );
};

export default ProductPage;
