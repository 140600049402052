import { Option } from 'components/dropdown';
import { config } from 'config';
import { brandProblemTypes } from './brands';
import { getProblemTypeFromString } from './misc';
import { ReviewReason } from 'graphql/types';

export const problemTypeOptions: Option[] = [
  { label: 'Erectile', value: 'ERECTILE' },
  { label: 'Premature', value: 'PREMATURE' },
  { label: 'Sleep', value: 'SLEEP' },
  { label: 'Mental Health - Good', value: 'MENTAL_HEALTH_GOOD' },
  { label: 'Mental Health - Meh', value: 'MENTAL_HEALTH_MEH' },
  { label: 'Mental Health - Sad', value: 'MENTAL_HEALTH_SAD' },
  { label: 'Acne', value: 'ACNE' },
  { label: 'Anti-ageing', value: 'ANTI_AGEING' },
  { label: 'Hair', value: 'HAIR' },
  { label: 'Contraception', value: 'CONTRACEPTION' },
  { label: 'Skin General', value: 'SKIN_GENERAL' },
  { label: 'Herpes', value: 'HERPES' },
  { label: 'Cold Sores', value: 'COLD_SORES' },
  { label: 'UTI', value: 'UTI' },
  { label: 'Fertility', value: 'FERTILITY' },
  { label: 'Fertility Hormone', value: 'FERTILITY_HORMONE' },
  { label: 'Preconception Fertility', value: 'PRECONCEPTION_FERTILITY' },
  {
    label: 'Preconception Nutrition & Lifestyle',
    value: 'PRECONCEPTION_NUTRITION',
  },
  {
    label: 'Preconception Mental Health',
    value: 'PRECONCEPTION_MENTAL_HEALTH',
  },
  { label: 'Preconception Physio', value: 'PRECONCEPTION_PHYSIO' },
  { label: 'Genetic Test', value: 'GENETIC_TEST' },
  { label: 'Pregnancy & Birth Planning', value: 'PREGNANCY' },
  { label: 'Pregnancy Nutrition & Lifestyle', value: 'PREGNANCY_NUTRITION' },
  { label: 'Pregnancy Mental Health', value: 'PREGNANCY_MENTAL_HEALTH' },
  { label: 'Pregnancy Physio', value: 'PREGNANCY_PHYSIO' },
  { label: 'Postpartum Consult', value: 'POSTPARTUM' },
  { label: 'Postpartum Nutrition', value: 'POSTPARTUM_NUTRITION' },
  { label: 'Postpartum Mental Health', value: 'POSTPARTUM_MENTAL_HEALTH' },
  { label: 'Postpartum Physio', value: 'POSTPARTUM_PHYSIO' },
  { label: 'Breastfeeding Consult', value: 'BREASTFEEDING' },
  { label: 'Infant Sleep', value: 'INFANT_SLEEP' },
  { label: 'STI', value: 'STI' },
  { label: 'Weight Loss', value: 'WEIGHT_LOSS' },
  { label: 'Menopause', value: 'MENOPAUSE' },
  { label: "Women's Health", value: 'WOMENS_HEALTH' },
].filter(({ value }) => {
  if (value === '') {
    return true;
  }

  const problemType = getProblemTypeFromString(value);
  if (!problemType) {
    throw new Error(`${value} is not a valid problem type`);
  }

  return brandProblemTypes[config.brand].includes(problemType);
});

export const productUsageOptions: Option[] = [
  { label: 'Daily', value: 'Daily' },
  { label: 'OnDemand', value: 'OnDemand' },
];

export const availabilityOptions: Option[] = [
  { label: 'Unavailable', value: 'false' },
  { label: 'Available', value: 'true' },
];

export const purchaseStatusFilterOptions: Option[] = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Cancelled', value: 'CANCELLED' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Paused', value: 'PAUSED' },
  { label: 'Pending Activation', value: 'PENDING_ACTIVATION' },
];

export const reasonOptions: { label: string; value: ReviewReason }[] = [
  { label: 'Change dose or medication', value: 'MEDICATION_CHANGES' },
  { label: 'Changes to medical history', value: 'MEDICAL_HISTORY_CHANGES' },
  { label: 'Expired script', value: 'EXPIRED_SCRIPT' },
  { label: 'External script request', value: 'EXTERNAL_SCRIPT_REQUEST' },
  { label: 'Medical enquiry', value: 'MEDICAL_INQUIRY' },
  { label: 'Not seeing results', value: 'NOT_SEEING_RESULTS' },
  { label: 'Pathology results', value: 'PATHOLOGY_RESULTS' },
  { label: 'Progress review', value: 'PROGRESS_REVIEW' },
  { label: 'Reactivation', value: 'REACTIVATION' },
  { label: 'Side effects', value: 'SIDE_EFFECTS' },
  { label: 'Transition off treatment', value: 'TRANSITION_OFF_TREATMENT' },
  {
    label: 'Urgent - Change dose or medication',
    value: 'URGENT_MEDICATION_CHANGES',
  },
  {
    label: 'Urgent - New script required',
    value: 'URGENT_NEW_SCRIPT_REQUIRED',
  },
  { label: 'Urgent - Side effects', value: 'URGENT_SIDE_EFFECTS' },
];
