import { useHistory, useLocation } from 'react-router-dom';
import { buildUrl } from './build-url';
import { useUrlQuery } from './use-url-query';

export type ChangeUrlParams = {
  url?: string;
  params: Record<string, unknown | unknown[]>;
  reset?: boolean;
};
function paramsToObject(query: URLSearchParams): Record<string, unknown> {
  const result: Record<string, unknown> = {};
  query.forEach((value, key) => {
    result[key] = value;
  });
  return result;
}

export function useChangeUrl(): (params: ChangeUrlParams) => void {
  const location = useLocation();
  const path = location.pathname;
  const history = useHistory();
  const query = useUrlQuery();
  return function changeUrl({
    url = path,
    params,
    reset = false,
  }: ChangeUrlParams): void {
    if (url === path && !reset) {
      history.push(
        buildUrl(url, {
          ...paramsToObject(query),
          ...params,
        }),
      );
    } else {
      history.push(buildUrl(url, params));
    }
  };
}
