import { FaExclamationTriangle, FaQuestionCircle } from 'react-icons/fa';

export interface TooltipProps {
  hoverText: string;
  link?: string;
  icon?: 'question' | 'warning';
}

const iconClassName = 'inline align-top text-sm mt-0.5';

export const Tooltip = ({
  hoverText,
  link,
  icon = 'question',
}: TooltipProps): React.ReactElement => {
  return (
    <>
      <span className="ml-1 mt-1">
        <span className="tooltip">
          <a href={link} target="_blank" rel="noreferrer">
            {icon == 'question' && (
              <FaQuestionCircle className={iconClassName} />
            )}
            {icon == 'warning' && (
              <FaExclamationTriangle className={iconClassName} />
            )}
            <span className="tooltiptext text-sm bg-black text-white p-2 rounded-md normal-case font-normal">
              {hoverText}
            </span>
          </a>
        </span>
      </span>
    </>
  );
};
