import SyncQueues, { PractitionerBookingWindowsDoc } from './sync';
import AsyncQueueTable from './async';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { useLocalStorage } from 'react-use';
import { useHistory } from 'react-router-dom';
import { ProblemType } from '@eucalyptusvc/lib-adapters';
import { usePracBookingWindowDates } from 'utils/queues';
import { gql, useQuery } from '@apollo/client';
import {
  AsyncConsultationQueueInput,
  ConsultationStage,
  QueuesQuery,
  QueuesQueryVariables,
  ReviewReason,
} from 'graphql/types';
import { isWithinInterval } from 'date-fns';
import { upperSnakeCaseToCapitalCase } from 'utils/misc';
import clsx from 'clsx';
import { useForm, useWatch } from 'react-hook-form';
import { reasonOptions } from 'utils/dropdown-options';
import { Dropdown, Option } from 'components/dropdown';
import { useState } from 'react';
import { Loading } from 'components/loading';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const typeFilterOptions: Option<ConsultationStage>[] = [
  { label: 'Initial', value: 'INITIAL' },
  { label: 'Followup', value: 'FOLLOW_UP' },
  { label: 'Review', value: 'REVIEW' },
];

const priorityFilterOptions: Option[] = [
  { label: 'All', value: '' },
  { label: '⭐', value: '1' },
  { label: '⭐⭐', value: '2' },
  { label: '⭐⭐⭐', value: '3' },
];

const tablePageSize = 30;

const Queues = () => {
  const history = useHistory();
  const [queueType, setQueueType] = useLocalStorage<'SYNC' | 'ASYNC'>(
    'queue-type',
    'SYNC',
  );
  const [selectedProblemType, setSelectedProblemType] = useLocalStorage<
    ProblemType | undefined
  >('admin-queue-problem-type');
  const [pageDetails, setPageDetails] = useState({
    currentPageNumber: 1,
    pageNumberToToken: new Map([[1, '']]),
  });

  const asyncFiltersForm = useForm<{
    consultationStages: ConsultationStage[];
    priority: string;
    reviewReasons: ReviewReason;
  }>({
    reValidateMode: 'onChange',
  });

  const featureFlagClient = useFeatureFlagClient();
  const ffAsyncConsultsEnabled = featureFlagClient.getBoolean(
    'ff-async-consults-enabled',
  );
  const asyncFiltersFormData = useWatch<{
    consultationStages: ConsultationStage[];
    priority: string;
    reviewReasons: ReviewReason[];
  }>({
    defaultValue: {
      consultationStages: [],
      priority: '',
      reviewReasons: [],
    },
    control: asyncFiltersForm.control,
  });

  const asyncConsultationQueueInput: AsyncConsultationQueueInput = {
    consultationStages: asyncFiltersFormData.consultationStages || undefined,
    priority: parseInt(asyncFiltersFormData.priority || '') || undefined,
    problemType: selectedProblemType || 'WEIGHT_LOSS',
    reviewReasons: asyncFiltersFormData.reviewReasons || undefined,
    pageRequest: {
      pageSize: tablePageSize,
      pageToken: pageDetails.pageNumberToToken.get(
        pageDetails.currentPageNumber,
      ),
    },
  };

  const dates = usePracBookingWindowDates();

  const { data, refetch, client } = useQuery<QueuesQuery, QueuesQueryVariables>(
    gql`
      ${PractitionerBookingWindowsDoc}
      ${AsyncQueueTable.queueTableFragment}
      ${AsyncQueueTable.moveToSyncQueueModalFragment}
      query Queues(
        $dates: [String!]!
        $asyncConsultationQueueInput: AsyncConsultationQueueInput!
        $skipAsyncConsultationQueue: Boolean!
      ) {
        brandConditions {
          id
          type
          supportsBookingWindows
          supportsAsyncConsultations
          patientsInAsyncQueue
        }
        practitionerBookingWindows(input: { dates: $dates }) {
          id
          ...PractitionerBookingWindow
          ...MoveToSyncQueueModalPractitionerBookingWindow
            @skip(if: $skipAsyncConsultationQueue)
        }
        asyncConsultationQueue(input: $asyncConsultationQueueInput)
          @skip(if: $skipAsyncConsultationQueue) {
          id
          totalEntryCount
          nextPageToken
          ...AsyncConsultationQueue
        }
      }
    `,
    {
      variables: {
        dates,
        asyncConsultationQueueInput,
        skipAsyncConsultationQueue: queueType !== 'ASYNC',
      },
      returnPartialData: true,
      onCompleted: ({ brandConditions, asyncConsultationQueue }) => {
        const defaultCondition = brandConditions
          ? brandConditions[0]
          : undefined;
        if (defaultCondition && !selectedProblemType) {
          setSelectedProblemType(defaultCondition.type);
        }

        const nextPageToken = asyncConsultationQueue?.nextPageToken;
        if (nextPageToken) {
          setPageDetails((prevPageDetails) => ({
            currentPageNumber: prevPageDetails.currentPageNumber,
            pageNumberToToken: new Map(prevPageDetails.pageNumberToToken).set(
              prevPageDetails.currentPageNumber + 1,
              nextPageToken,
            ),
          }));
        }
      },
    },
  );

  const activeWindowForSelectedProblemType =
    data?.practitionerBookingWindows?.find(
      (w) =>
        w.problemType === selectedProblemType &&
        isWithinInterval(new Date(), {
          start: new Date(w.startAt),
          end: new Date(w.endAt),
        }),
    );
  const brandConditionsForSelectedProblemType = data?.brandConditions?.find(
    (bc) => bc.type === selectedProblemType,
  );

  return (
    <div>
      <div className="flex flex-col space-y-4">
        {data?.brandConditions && (
          <ul className="flex space-x-4 font-semibold">
            {data.brandConditions
              .filter(
                (bc) =>
                  bc.supportsBookingWindows || bc.supportsAsyncConsultations,
              )
              .map(({ type, patientsInAsyncQueue }) => {
                const isActive = type === selectedProblemType;

                const activeWindowForProblemType =
                  data?.practitionerBookingWindows?.find(
                    (w) =>
                      w.problemType === type &&
                      isWithinInterval(new Date(), {
                        start: new Date(w.startAt),
                        end: new Date(w.endAt),
                      }),
                  );

                const activeWindowUnassignedBookingsCount =
                  activeWindowForProblemType?.unassignedBookingsCount ?? 0;

                const queueCount =
                  activeWindowUnassignedBookingsCount +
                  (ffAsyncConsultsEnabled ? patientsInAsyncQueue ?? 0 : 0);

                return (
                  <li
                    key={type}
                    className="cursor-pointer"
                    onClick={(): void => {
                      setSelectedProblemType(type);
                      history.replace({ search: '' }); // clear pageIndex search param
                    }}
                  >
                    <div>
                      <p
                        className={clsx({
                          'text-primary': isActive,
                          'text-primary-300': !isActive,
                        })}
                      >
                        {upperSnakeCaseToCapitalCase(type)} {`(${queueCount})`}
                      </p>
                      {isActive && (
                        <div className=" border-b-2 border-primary pt-2" />
                      )}
                    </div>
                  </li>
                );
              })}
          </ul>
        )}

        <div className="border-t border-gray-300" />

        {ffAsyncConsultsEnabled && (
          <fieldset className="flex space-x-2">
            <label>
              <div
                className={`border border-r border-slate-600 py-1 p-2 rounded-full ${
                  queueType === 'SYNC' ? 'text-white bg-primary' : ''
                }`}
              >
                Sync: phone call (
                {activeWindowForSelectedProblemType?.unassignedBookingsCount ??
                  '–'}
                /{activeWindowForSelectedProblemType?.capacity ?? '–'})
              </div>
              <input
                type="radio"
                name="queue-type"
                value="SYNC"
                checked={queueType === 'SYNC'}
                onChange={() => setQueueType('SYNC')}
                className="hidden"
              />
            </label>
            <label>
              <div
                className={`border border-r border-slate-600 py-1 px-2 rounded-full ${
                  queueType === 'ASYNC' ? 'text-white bg-primary' : ''
                }`}
              >
                Async: text based (
                {brandConditionsForSelectedProblemType?.patientsInAsyncQueue ??
                  '–'}
                )
              </div>
              <input
                type="radio"
                name="queue-type"
                value="ASYNC"
                checked={queueType === 'ASYNC'}
                onChange={() => setQueueType('ASYNC')}
                className="hidden"
              />
            </label>
          </fieldset>
        )}
      </div>

      {queueType === 'SYNC' &&
        selectedProblemType &&
        (data?.practitionerBookingWindows ? (
          <div className="my-4">
            <SyncQueues
              refetch={refetch}
              problemType={selectedProblemType}
              practitionerBookingWindows={data?.practitionerBookingWindows}
            />
          </div>
        ) : (
          <div className="flex justify-center text-lg my-4">
            <Loading />
          </div>
        ))}
      {queueType === 'ASYNC' && (
        <div className="space-y-2">
          <form className="flex justify-between space-x-2 my-4">
            <div className="flex-grow">
              <Dropdown
                name="consultationStages"
                label="Consult Type"
                isMulti={true}
                options={typeFilterOptions}
                control={asyncFiltersForm.control}
              />
            </div>
            <div className="flex-grow">
              <Dropdown
                name="reviewReasons"
                label="Reason (review consults only)"
                isMulti={true}
                options={reasonOptions}
                control={asyncFiltersForm.control}
              />
            </div>
            <div className="flex-grow">
              <Dropdown
                name="priority"
                label="Priority"
                options={priorityFilterOptions}
                control={asyncFiltersForm.control}
              />
            </div>
          </form>
          {data?.asyncConsultationQueue?.entries ? (
            <div>
              <AsyncQueueTable
                bookingWindows={data.practitionerBookingWindows ?? []}
                rowData={data.asyncConsultationQueue.entries}
              />
              <div className="w-full flex justify-end pt-2">
                <button
                  className="w-10 h-10 ml-2 first:ml-0 border rounded text-gray-600 border-gray-600 hover:bg-gray-400 disabled:text-gray-500 disabled:border-gray-500 disabled:cursor-not-allowed"
                  onClick={() => {
                    client.cache.evict({
                      fieldName: 'asyncConsultationQueue',
                    });
                    client.cache.gc();

                    setPageDetails((prevPageDetails) => ({
                      currentPageNumber: prevPageDetails.currentPageNumber - 1,
                      pageNumberToToken: new Map(
                        prevPageDetails.pageNumberToToken,
                      ),
                    }));
                  }}
                  disabled={
                    pageDetails.pageNumberToToken.get(
                      pageDetails.currentPageNumber - 1,
                    ) === undefined
                  }
                >
                  <FaChevronLeft className="mx-auto stroke-current" />
                </button>
                <div className="px-2 flex justify-center items-center">
                  Page {pageDetails.currentPageNumber} of{' '}
                  {Math.ceil(
                    data.asyncConsultationQueue.totalEntryCount / tablePageSize,
                  )}
                </div>
                <button
                  className="w-10 h-10 ml-2 first:ml-0 border rounded text-gray-600 border-gray-600 hover:bg-gray-400 disabled:text-gray-500 disabled:border-gray-500 disabled:cursor-not-allowed"
                  onClick={() => {
                    client.cache.evict({
                      fieldName: 'asyncConsultationQueue',
                    });
                    client.cache.gc();
                    setPageDetails((prevPageDetails) => ({
                      currentPageNumber: prevPageDetails.currentPageNumber + 1,
                      pageNumberToToken: new Map(
                        prevPageDetails.pageNumberToToken,
                      ),
                    }));
                  }}
                  disabled={
                    pageDetails.pageNumberToToken.get(
                      pageDetails.currentPageNumber + 1,
                    ) === undefined
                  }
                >
                  <FaChevronRight className="mx-auto stroke-current" />
                </button>
              </div>
            </div>
          ) : (
            <div className="flex justify-center text-lg">
              <Loading />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Queues;
