import { gql, useMutation, useQuery } from '@apollo/client';
import { AssignModal } from 'components/assign-modal';
import { Button } from 'components/button';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import CustomerDetails from 'components/customer-details';
import { Loading } from 'components/loading';
import { Modal } from 'components/modal';
import { useHasPermissions } from 'components/permissions';
import { Snipplet } from 'components/snipplet';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePaginatingSortingTable,
} from 'components/table';
import { Tag } from 'components/tag';
import { config } from 'config';
import { isFuture, parseISO } from 'date-fns';
import {
  ConsultationQuery,
  ConsultationQueryVariables,
  ConsultationStatus,
  ConsultationTag,
  CreatePathRequestMutation,
  CreatePathRequestMutationVariables,
  Maybe,
  NoteRowFragment,
  PathologyRequestFragment,
  PrescribedSequenceStatus,
  PrescribingExperience,
} from 'graphql/types';
import { BandedRow } from 'pages/customer/bandedRow';
import React, { FunctionComponent, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import {
  Channel,
  MessageList,
  Thread,
  Window,
  useChatContext,
} from 'stream-chat-react';
import {
  Colors,
  formatDate,
  formatDateAndTime,
  formatPatientName,
  upperSnakeCaseToCapitalCase,
  getConsultationStatusFromString,
  getPathologyStatusColor,
  consultationStageTitle,
} from 'utils/misc';
import { isPathologyProblemType } from 'utils/pathology';
import { isNonRxConsultation } from 'utils/patient/is-non-rx-consultation';
import { buildRoute, routes } from 'utils/routes';
import { Accordion } from '../../components/accordion';
import { DeleteConsultationButton } from '../../components/delete-consultation-button';
import { Markdown } from '../../components/markdown';
import { BlurredAttachment } from '../../components/stream/attachment';
import { quizResponseFragment } from '../../fragments';
import { JobTable } from '../customer/jobTable';
import './chat-styles.css';
import { Messages } from './messages/messages';
import { Question } from './messages/types';
import { ConsultationPhoneCalls } from './phone-calls';
import { Questionnaire } from './questionnaire';
import { ConsultationReminder } from './reminder';
import { ConsultationStatusSelection } from './status/constultation-status-selection';
import { ConsultationJobView } from './types';
import { noteRowFragment } from 'components/notes/notes-table';
import { ConsultationNotes } from './consultation-notes';
import { addNoteModalConsultationFragment } from 'components/notes/add-note/add-note-modal';
import { CriticalNoteCard } from 'components/notes/critical-note/critical-note-card';
import { customerCriticalNoteDocument } from 'components/notes/critical-note/critical-note-card';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { SmallTableHeadings } from 'components/small-table/small-table-headings';
import { PractitionerBookingHistoryDrawerButton } from './practitionerBookingHistoryDrawerButton';

type ConsultationType = ConsultationQuery['consultation'];

const columnHeader = 'heading-md mb-2';
const sectionHeader = 'heading-sm mb-3';

const pathologyRequestFragment = gql`
  fragment pathologyRequest on PathologyRequest {
    id
    createdAt
    status
    reference
    panels {
      id
    }
    doctor {
      id
      firstName
      lastName
      fullName
      clinicianName
    }
  }
`;

export const Consultation = (): React.ReactElement => {
  const { consultationId } = useParams<{ consultationId: string }>();

  const {
    data,
    loading,
    refetch: refetchConsultation,
  } = useQuery<ConsultationQuery, ConsultationQueryVariables>(
    gql`
      query Consultation(
        $where: ConsultationWhereUniqueInput!
        $consultationId: ID!
      ) {
        consultation(where: $where) {
          id
          createdAt
          updatedAt
          status
          type
          isApproved
          stage
          rejectReason
          patientNotes
          medium
          approvedWithPreviousScriptId
          canBeDeleted
          prescribingExperience
          tags
          queueEntry {
            id
            __typename
          }
          phoneCalls {
            id
            status
            calleeNumber
            phoneCallProviderDetails {
              id
              ... on ZoomPhoneCallDetails {
                startedRingingAt
                duration
              }
            }
            caller {
              id
              clinicianName
            }
          }
          booking
          chatThread {
            id
          }
          quizApplication {
            id
            isNonEnglish
            languageDisplayName
            status
            numberOfAnsweredQuestions
            submittedAt
          }
          quizResponses {
            ...quizResponse
          }
          screeningQuizApplication {
            id
            isNonEnglish
            languageDisplayName
            responses {
              ...quizResponse
            }
          }
          appointment {
            calendarId
            calendarName
          }
          pathologyRequests {
            ...pathologyRequest
          }

          prescribedSequences {
            id
            status
            createdAt
            minimumStartDate
            sequence {
              id
              friendlyName
            }
          }

          doctorQuestions {
            id
            createdAt
            updatedAt
            value
            allowedAnswerType
            answer {
              id
              createdAt
              updatedAt
              value
              imageUrl
            }
            attachments {
              id
              filename
              mimetype
              signedUrl
            }
          }
          patientQuestions {
            id
            createdAt
            updatedAt
            value
          }

          logs(orderBy: { updatedAt: asc }) {
            id
            updatedAt
            updatedBy {
              id
              email
              firstName
              lastName
              fullName
              role
            }
            data
            event
            status
          }
          customer {
            id
            firstName
            lastName
            fullName
            email
            phone
            ...CustomerCriticalNote
          }
          doctor {
            id
            firstName
            lastName
            fullName
            clinicianName
          }
          jobs {
            id
            updatedAt
            type
            message
            source
            canReschedule
            scheduledAt
            doneAt
            createdAt
            data
            canceled
            consultationId
            failed
          }
          reminders {
            id
            canceled
            doneAt
            scheduledAt
          }
          reviewReason

          practitionerBookingEvents {
            id
            assignedByDetails
            bookedByDetails
            joinedQueueAt
            removedAtDetails
            windowDetails
          }
          ...AddNoteModalConsultation
        }

        filteredNotes(input: { consultationId: $consultationId }) {
          ...noteRow
        }
      }
      ${quizResponseFragment}
      ${pathologyRequestFragment}
      ${noteRowFragment}
      ${addNoteModalConsultationFragment}
      ${customerCriticalNoteDocument}
    `,
    {
      variables: {
        where: {
          id: consultationId,
        },
        consultationId,
      },
    },
  );

  if (loading) {
    return (
      <div className="flex justify-center text-lg">
        <Loading />
      </div>
    );
  }

  const consultation = data?.consultation;

  if (!consultation) {
    return (
      <div className="text-center font-medium pt-8">Consultation not found</div>
    );
  }

  return (
    <section className="flex space-x-4">
      <div className="w-3/4">
        <ConsultationBody
          consultation={consultation}
          notes={data?.filteredNotes ?? []}
        />
      </div>

      <div className="w-1/4 border-l-2 px-4">
        <ConsultationAside
          consultation={consultation}
          refetchConsultation={refetchConsultation}
        />
      </div>
    </section>
  );
};

const Chat: FunctionComponent<{
  threadId: Maybe<string>;
}> = ({ threadId }) => {
  const { client } = useChatContext();
  if (!client) {
    return (
      <>
        No chat connection created. Please contact{' '}
        <a href="https://eucalyptus-vc.slack.com/archives/CVB1LPNQN">
          #help-technology
        </a>
      </>
    );
  }

  if (!threadId) {
    return null;
  }

  const channel = client.getChannelById('messaging', threadId, {});

  return (
    <div className="bg-white">
      <Accordion title={'Chat'}>
        <Channel channel={channel} Attachment={BlurredAttachment}>
          <Window>
            <MessageList />
          </Window>
          <Thread />
        </Channel>
      </Accordion>
    </div>
  );
};

const ConsultationBody = ({
  consultation,
  notes,
}: {
  consultation: NonNullable<ConsultationType>;
  notes: NoteRowFragment[];
}): React.ReactElement => {
  return (
    <section className="space-y-4">
      <PatientLetter consultation={consultation} />
      {consultation.phoneCalls && consultation?.phoneCalls?.length > 0 && (
        <ConsultationPhoneCalls phoneCalls={consultation.phoneCalls} />
      )}
      {isPathologyProblemType(consultation.type) &&
        !!consultation.pathologyRequests?.length && (
          <ConsultationPathologyResults consultation={consultation} />
        )}
      <Chat threadId={consultation.chatThread?.id} />
      <ConsultationMessages consultation={consultation} />
      <ConsultationPrescribedSequences consultation={consultation} />
      <CriticalNoteCard customer={consultation.customer} />
      <ConsultationNotes
        customerId={consultation.customer.id}
        notes={notes}
        consultation={consultation}
      />
      <ConsultationJobs consultation={consultation} />
      <ConsultationQuestionnaire consultation={consultation} />
      <ConsultationLogs consultation={consultation} />
    </section>
  );
};

const ConsultationQuestionnaire = ({
  consultation,
}: {
  consultation: NonNullable<ConsultationType>;
}): React.ReactElement => {
  const isInitialStage = consultation.stage === 'INITIAL';
  const headings = [
    'Quiz Application ID',
    'Status',
    'Submitted At',
    'Number of Answered Questions',
  ];
  return (
    <>
      <div className="mb-4 bg-white">
        <Accordion
          title={`${consultation.stage} ${
            isInitialStage ? 'Screening' : 'Quiz'
          }`}
        >
          <div className="overflow-x-auto">
            <div className="min-w-full">
              <table className="min-w-full divide-y divide-gray-200 mb-6">
                <thead>
                  <SmallTableHeadings headings={headings} />
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 mb-4">
                  <tr key={consultation.id}>
                    <td className="px-6 p-2 text-sm text-gray-900 white-space: nowrap;">
                      {consultation?.quizApplication?.id || 'None'}
                    </td>
                    <td className="px-6 p-2 text-sm text-gray-900 whitespace-nowrap">
                      {consultation?.quizApplication?.status}
                    </td>
                    <td className="px-6 p-2 text-sm text-gray-900 whitespace-nowrap">
                      {consultation?.quizApplication?.submittedAt &&
                        formatDate(consultation.quizApplication.submittedAt)}
                    </td>
                    <td className="px-6 р-2 text-sm text-gray-900 whitespace-nowrap">
                      {consultation?.quizApplication?.numberOfAnsweredQuestions}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <Questionnaire
            quizResponses={consultation.quizResponses ?? []}
            isNonEnglish={consultation?.quizApplication?.isNonEnglish}
            languageDisplayName={
              consultation?.quizApplication?.languageDisplayName
            }
          />
        </Accordion>
      </div>
      {isInitialStage && (
        <div className="mb-4 bg-white">
          <Accordion title={'Medical Screening'}>
            <div className="bg-gray-100 px-4 py-2 mb-3 flex border">
              <div className="mr-6 text-sm leading-5 font-medium text-gray-500">
                Quiz Application ID
              </div>
              <div className="text-sm leading-5 text-gray-500">
                {consultation.screeningQuizApplication?.id}
              </div>
            </div>
            <Questionnaire
              quizResponses={
                consultation.screeningQuizApplication?.responses ?? []
              }
              isNonEnglish={consultation.screeningQuizApplication?.isNonEnglish}
              languageDisplayName={
                consultation.screeningQuizApplication?.languageDisplayName
              }
            />
          </Accordion>
        </div>
      )}
    </>
  );
};

const ConsultationJobs = ({
  consultation,
}: {
  consultation: NonNullable<ConsultationType>;
}): React.ReactElement => {
  const jobs =
    consultation?.jobs?.map<ConsultationJobView>((job) => ({
      id: job.id,
      updatedAt: new Date(job.updatedAt),
      type: job.type,
      scheduledAt: job.scheduledAt ? new Date(job.scheduledAt) : undefined,
      doneAt: job.doneAt ? new Date(job?.doneAt) : undefined,
      message: job.message ?? undefined,
      source: job.source ?? undefined,
      createdAt: new Date(job.createdAt),
      data: job?.data,
      consultationId: job.consultationId ?? undefined,
      canceled: job.canceled,
      canReschedule: job.canReschedule ?? false,
      failed: job.failed,
    })) || [];

  return (
    <div className="mb-4">
      <JobTable jobs={jobs} />
    </div>
  );
};

const ConsultationPrescribedSequences = ({
  consultation,
}: {
  consultation: NonNullable<ConsultationType>;
}): React.ReactElement | null => {
  const sortedPrescribedSequences = useMemo(
    () =>
      consultation.prescribedSequences
        ?.slice(0)
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
    [consultation.prescribedSequences],
  );

  if (!sortedPrescribedSequences?.length) {
    return null;
  }

  return (
    <div className="mb-4">
      <div className="px-4 py-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Prescribed Sequences{' '}
          <span className="ml-2 text-gray-500">
            {sortedPrescribedSequences?.length ?? 0}
          </span>
        </h3>
      </div>
      {sortedPrescribedSequences?.map((ps) => (
        <div
          className="border-t border-b border-gray-200 bg-white mb-4"
          key={ps.id}
        >
          <Accordion
            title={ps.sequence?.friendlyName ?? 'Prescribed Sequence'}
            tags={[
              {
                text: upperSnakeCaseToCapitalCase(ps.status),
                color: getPrescribedSequenceStatusColor(ps.status),
                shape: 'box',
                size: 'small',
              },
            ]}
            startOpen={ps.status !== 'DELETED'}
          >
            <BandedRow
              bgColor="grey"
              label="Sequence ID"
              value={
                ps.sequence && (
                  <Link
                    to={buildRoute.sequence(ps.sequence.id)}
                    className="text-gray-500"
                  >
                    {ps.sequence.id}
                  </Link>
                )
              }
              placeholderValue="-"
            />
            <BandedRow
              bgColor="grey"
              label="Created At"
              value={formatDateAndTime(ps.createdAt)}
              placeholderValue="-"
            />
            <BandedRow
              bgColor="grey"
              label="Minimum Start Date"
              value={ps.minimumStartDate}
              placeholderValue="-"
            />
          </Accordion>
        </div>
      ))}
    </div>
  );
};

const getPrescribedSequenceStatusColor = (
  status: PrescribedSequenceStatus,
): Colors => {
  switch (status) {
    case 'ACTIVATED':
      return 'green';
    case 'PENDING':
      return 'dark-blue';
    case 'DELETED':
    default:
      return 'gray';
  }
};

const columns: Column<PathologyRequestFragment>[] = [
  {
    Header: 'Reference no',
    accessor: 'reference',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/4',
  },
  {
    Header: 'Practitioner',
    accessor: 'doctor',
    Cell: (c) => <div>{c.row.original.doctor?.clinicianName}</div>,
    className: 'w-1/4',
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (c) => (
      <Tag size="small" color={getPathologyStatusColor(c.row.original?.status)}>
        {c.row.original?.status}
      </Tag>
    ),
    className: 'w-1/4',
    disableSortBy: true,
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: (c) => <div>{c.value ? formatDate(new Date(c.value)) : ''}</div>,
    className: 'w-1/4',
  },
];

const ConsultationPathologyResults = ({
  consultation,
}: {
  consultation: NonNullable<ConsultationType>;
}): React.ReactElement => {
  const history = useHistory();

  const pathologyRequests = consultation?.pathologyRequests ?? [];

  const tableInstance = usePaginatingSortingTable({
    data: pathologyRequests.filter((r): r is NonNullable<typeof r> => !!r),
    columns,
    pageNumber: 1,
  });

  if (pathologyRequests.length === 0) {
    return <p>No Pathology results</p>;
  }
  return (
    <div className="mb-4 bg-white">
      <Accordion title={'Pathology Results'}>
        <div className="p-4">
          <Table tableInstance={tableInstance}>
            <TableHead />
            <TableBody>
              <React.Fragment>
                {tableInstance.page?.map((row) => {
                  tableInstance.prepareRow(row);
                  return (
                    <TableRow row={row} key={row.id}>
                      <React.Fragment>
                        {row.cells.map((cell) => (
                          <TableCell
                            key={`${cell.row.original.id}-${cell.column.id}`}
                            cell={cell}
                            onClick={(): void => {
                              history.push(
                                `${routes.pathologies}/${cell.row.original.id}`,
                              );
                            }}
                          />
                        ))}
                      </React.Fragment>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            </TableBody>
          </Table>
        </div>
      </Accordion>
    </div>
  );
};

const ConsultationLogs = ({
  consultation,
}: {
  consultation: NonNullable<ConsultationType>;
}): React.ReactElement => (
  <div className="mb-4 bg-white">
    <Accordion title={'Audit'}>
      <div className="px-4 pb-4">
        <table className="table-auto w-full">
          <thead>
            <tr className="text-left font-semibold">
              <th>Updated At</th>
              <th>Event</th>
              <th>Status</th>
              <th>Data</th>
              <th>Updated By</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {consultation.logs.map((log) => (
              <tr key={log.id} className="border-t h-12">
                <td>{formatDateAndTime(log.updatedAt)}</td>
                <td>{log.event}</td>
                <td>{log.status}</td>
                <td>{JSON.stringify(log.data)}</td>
                <td>{log.updatedBy?.fullName}</td>
                <td>{log.updatedBy?.role}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Accordion>
  </div>
);

const ConsultationMessages: FunctionComponent<{
  consultation: NonNullable<ConsultationType>;
}> = ({ consultation }) => {
  if (
    !consultation.patientQuestions.length &&
    !consultation.doctorQuestions.length
  ) {
    return null;
  }

  const customer = consultation.customer;
  const problemType = consultation.type;
  if (!customer) {
    return <div>No patient</div>;
  }

  // @ts-expect-error: FIXME, this needs proper typing
  const questions: Question[] = [
    ...(consultation.patientQuestions?.map((data) => ({
      doctorInitiated: false,
      data,
    })) ?? []),
    ...(consultation.doctorQuestions?.map((data) => ({
      doctorInitiated: true,
      data,
    })) ?? []),
  ];

  return (
    <div className="mb-4 bg-white">
      <Accordion title={'Messages'}>
        <Messages
          patientName={formatPatientName(customer)}
          customerId={consultation.customer?.id}
          problemType={problemType}
          questions={questions}
        />
      </Accordion>
    </div>
  );
};

const PatientLetter = ({
  consultation,
}: {
  consultation: NonNullable<ConsultationType>;
}): React.ReactElement => {
  const isApproved = consultation.isApproved === true;
  const isRejected = consultation.isApproved === false;

  // Consultation.isApproved is true if approved, and false if not approved.
  // It can also be undefined if the consultation is in neither approved or not approved
  return (
    <div className="mb-4 bg-white">
      {isApproved && (
        <Accordion title={'Treatment'}>
          <div className="px-4 pb-4">
            <h2 className="font-semibold mb-3">Patient Letter</h2>
            {consultation.patientNotes && (
              <Markdown src={consultation.patientNotes} />
            )}
          </div>
        </Accordion>
      )}
      {isRejected && (
        <Accordion title={'Declined Patient'} type="danger">
          <div className="px-4 pb-4">
            <h2 className="font-semibold mb-3">Patient Letter</h2>
            {consultation.rejectReason && (
              <Markdown src={consultation.rejectReason} />
            )}
          </div>
        </Accordion>
      )}
      {isNonRxConsultation(consultation) &&
        consultation.status === 'CUSTOMER_COMPLETED' && (
          <Accordion title={'Consultation Notes'}>
            <div className="whitespace-pre-line px-4 pb-4">
              {consultation?.patientNotes}
            </div>
          </Accordion>
        )}
    </div>
  );
};

const statusUpdateNotAllowed = (
  status: ConsultationStatus,
  prescribingExperience: PrescribingExperience | null | undefined,
  isApproved: boolean,
): boolean => {
  const isFlexi = prescribingExperience === 'FLEXI';

  // Logic from https://linear.app/eucalyptus/issue/TXN-466/disallow-terminating-consult-status-transitions
  return (
    (isFlexi && status === 'DOCTOR_COMPLETED' && !isApproved) ||
    (isFlexi && status === 'CANCELED')
  );
};

const useDisabledConsultStatuses = (): ConsultationStatus[] => {
  const featureFlagClient = useFeatureFlagClient();

  return useMemo(() => {
    const potentialDisabledStatuses = featureFlagClient.getJson(
      'disabled-consultation-statuses',
    );
    let disabledStatuses: ConsultationStatus[] = [];
    if (isConsultStatusArray(potentialDisabledStatuses)) {
      disabledStatuses = potentialDisabledStatuses;
    }
    return disabledStatuses;
  }, [featureFlagClient]);
};

const isConsultStatusArray = (
  statuses: unknown,
): statuses is ConsultationStatus[] => {
  if (
    Array.isArray(statuses) &&
    statuses.every((s) => getConsultationStatusFromString(s))
  ) {
    return true;
  }
  return false;
};

const ConsultationAside = ({
  consultation,
  refetchConsultation,
}: {
  consultation: NonNullable<ConsultationType>;
  refetchConsultation: () => void;
}): React.ReactElement | null => {
  const canEditPathologies = useHasPermissions(['EDIT_PATHOLOGIES']);

  const customer = consultation.customer;
  const doctor = consultation.doctor;

  const history = useHistory();

  const [assignModalActive, setAssignModalActive] = React.useState(false);

  const pendingReminder = consultation.reminders?.find((reminder) => {
    return (
      !reminder.canceled &&
      !reminder.doneAt &&
      reminder.scheduledAt &&
      isFuture(parseISO(reminder.scheduledAt))
    );
  });

  const showMissingPathology =
    isPathologyProblemType(consultation.type) &&
    !consultation.pathologyRequests?.length &&
    canEditPathologies &&
    ['DOCTOR_ASSIGNED', 'AWAITING_RESULTS'].includes(consultation.status);

  const featureFlagClient = useFeatureFlagClient();
  const showConsultationTags = featureFlagClient.getBoolean(
    'ff_enable_consultation_tags',
  );

  const [createPathRequest, { loading }] = useMutation<
    CreatePathRequestMutation,
    CreatePathRequestMutationVariables
  >(gql`
    mutation CreatePathRequest($input: CreatePathologyRequestInput!) {
      createPathologyRequest(input: $input) {
        consultation {
          id
          pathologyRequests {
            id
          }
        }
      }
    }
  `);

  const disabledConsultationStatuses = useDisabledConsultStatuses();
  const statusUpdateDisabled =
    disabledConsultationStatuses.includes(consultation.status) ||
    statusUpdateNotAllowed(
      consultation.status,
      consultation.prescribingExperience,
      consultation.isApproved ?? true,
    );

  return (
    <section>
      <h2 className={columnHeader}>Consultation Details</h2>

      {customer && (
        <CustomerDetails customer={customer} problemType={consultation.type} />
      )}

      <h3 className={sectionHeader}>Consult Type</h3>
      <div className="mb-4">
        {consultationStageTitle(consultation.stage, consultation.reviewReason)}
      </div>

      <h3 className={sectionHeader}>Status</h3>
      <ConsultationStatusSelection
        status={consultation.status}
        disabled={statusUpdateDisabled}
        consultationId={consultation.id}
        onUpdate={refetchConsultation}
      />
      <div className="mb-4">
        <div className="w-1/2 mt-4">
          <Button
            fullWidth
            variant="outline"
            onClick={(): void => setAssignModalActive(true)}
          >
            {consultation.status === 'AWAITING_DOCTOR' ? 'Assign' : 'Reassign'}
          </Button>
        </div>
        <Modal
          show={assignModalActive}
          isAutoOverflow={false}
          onClose={(): void => setAssignModalActive(false)}
        >
          <AssignModal
            consultationIds={[consultation.id]}
            onAssigned={(): void => {
              history.go(0);
            }}
          />
        </Modal>
      </div>

      <h3 className={sectionHeader}>Condition Type</h3>
      <div className="mb-4">
        {upperSnakeCaseToCapitalCase(consultation.type)}
      </div>

      <h3 className={sectionHeader}>Consult ID</h3>
      <div className="mb-4">
        <div>
          {consultation.id}
          <CopyToClipboardButton value={consultation.id} />
        </div>
      </div>

      {showConsultationTags && (
        <>
          <h3 className={sectionHeader}>Tags</h3>
          <div className="mb-4 flex flex-direction-row gap-2 justify-left flex-wrap">
            {consultation.tags.map((tag) => (
              <Tag key={tag} color="gray">
                {formatConsultationTag(tag)}
              </Tag>
            ))}
          </div>
        </>
      )}

      <h3 className={sectionHeader}>Link to consult</h3>
      <div className="mb-4">
        <Snipplet
          type="copy"
          text={`${config.userAppUrl}/consultation/${consultation.id}`}
        />
      </div>

      <h3 className={sectionHeader}>Practitioner link</h3>
      <div className="mb-4">
        <Snipplet
          type="copy"
          text={`${config.doctorsAppUrl}/patients/${customer.id}?problemType=${consultation.type}`}
        />
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <h3 className="heading-sm">Created</h3>
          <div>
            {consultation.createdAt &&
              new Date(consultation.createdAt).toDateString()}
          </div>
        </div>
        <div className="w-1/2">
          <h3 className="heading-sm">Last Updated</h3>
          <div>
            {consultation.updatedAt &&
              new Date(consultation.updatedAt).toDateString()}
          </div>
        </div>
      </div>

      <h3 className={sectionHeader}>Practitioner</h3>
      <div className="mb-4">
        {doctor ? (
          <Link to={`${routes.clinicians}/${doctor?.id}`}>
            <Tag>{doctor.clinicianName}</Tag>
          </Link>
        ) : (
          <span>N/A</span>
        )}
      </div>

      {consultation.queueEntry && (
        <>
          <h3 className={sectionHeader}>Queue</h3>
          <div className="mb-4">
            {consultation.queueEntry?.__typename.replace(
              'ConsultationQueueEntry',
              '',
            )}
          </div>
        </>
      )}

      <h3 className={sectionHeader}>Queue Booking Window</h3>
      <div className="flex justify-between mb-4">
        <div>{consultation.booking ?? 'N/A'}</div>
        <PractitionerBookingHistoryDrawerButton
          history={consultation.practitionerBookingEvents ?? []}
        />
      </div>

      <h3 className={sectionHeader}>Medium</h3>
      <div className="mb-4">{consultation.medium}</div>

      <h3 className={sectionHeader}>Appointment</h3>
      <div className="mb-4">
        {consultation.appointment ? (
          <>
            <p>
              <span className="font-medium">Calendar ID:</span>{' '}
              {consultation.appointment.calendarId}
            </p>
            <p>
              <span className="font-medium">Calendar name:</span>{' '}
              {consultation.appointment.calendarName}
            </p>
          </>
        ) : (
          <span>N/A</span>
        )}
      </div>

      <h3 className={sectionHeader}>Previous script</h3>
      <div className="mb-4 text-gray-500">
        {consultation.approvedWithPreviousScriptId ? (
          <div>
            <Link
              to={`${routes.scripts}/${consultation.approvedWithPreviousScriptId}`}
            >
              {consultation.approvedWithPreviousScriptId}
            </Link>
            <CopyToClipboardButton
              value={consultation.approvedWithPreviousScriptId}
            />
          </div>
        ) : (
          <span>N/A</span>
        )}
      </div>

      <ConsultationReminder
        reminderScheduledAt={pendingReminder?.scheduledAt ?? undefined}
        consultationId={consultation.id}
      />

      {showMissingPathology && (
        <div className="mb-4 bg-white">
          <Accordion title={'Missing Pathology Request'}>
            <div className="px-4 pb-4">
              <p>
                This consultation may require a pathology request, but the
                patient does not have one.
              </p>
              <div className="pt-3">
                <Button
                  fullWidth
                  size="small"
                  variant="outline"
                  disabled={loading}
                  loading={loading}
                  onClick={() =>
                    createPathRequest({
                      variables: {
                        input: {
                          consultationId: consultation.id,
                        },
                      },
                    })
                  }
                >
                  Generate
                </Button>
              </div>
            </div>
          </Accordion>
        </div>
      )}

      <DeleteConsultationButton
        consultationId={consultation.id}
        customerId={consultation.customer.id}
        disabled={!consultation.canBeDeleted}
      />
    </section>
  );
};

const formatConsultationTag = (tag: ConsultationTag): string => {
  switch (tag) {
    case 'AWAITING_BLOOD_PRESSURE':
      return 'Blood Pressure';
    case 'AWAITING_PHOTOS':
      return 'Photos';
    case 'AWAITING_REPLY':
      return 'Awaiting Reply';
    case 'AWAITING_CONSENT':
      return 'Awaiting Consent';
    case 'PRIORITY':
      return 'Priority';
    case 'TECHNICAL_ISSUE':
      return 'Technical Issue';
    case 'EXTERNAL_SCRIPT_REQUIRED':
      return 'External Script Required';
    case 'MISC_1':
      return 'Misc. 1';
    case 'MISC_2':
      return 'Misc. 2';
    case 'MISC_3':
      return 'Misc. 3';
    default:
      return 'Unknown';
  }
};

export default Consultation;
