import { LogsAccordion } from './logs-accordion';

export const TableOfObject = ({
  obj,
}: {
  obj: Record<string, string>;
}): React.ReactElement => {
  const keys = Object.keys(obj);
  if (keys.length === 0) {
    return <></>;
  }
  if (keys.length === 1) {
    return (
      <div>
        <span className="font-semibold">{keys[0]}: </span>
        <span>{obj[keys[0]]}</span>
      </div>
    );
  }
  return (
    <LogsAccordion>
      <table>
        <tbody>
          {Object.entries(obj).map(([property, value], index) => {
            return (
              <tr key={`${property}-${index}`}>
                <td className="px-6 py-2 whitespace-nowrap font-semibold">
                  {property}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">{value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </LogsAccordion>
  );
};
