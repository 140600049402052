import { PropsWithChildren } from 'react';
import { Tooltip, TooltipProps } from 'components/tooltip';

interface Props {
  htmlFor?: string;
  tooltip?: TooltipProps;
}

export const Label = ({
  children,
  htmlFor,
  tooltip,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <>
      {children && (
        <label htmlFor={htmlFor} className="heading-sm block">
          {children}
          {tooltip && <Tooltip {...tooltip} />}
        </label>
      )}
    </>
  );
};
