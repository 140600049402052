import { gql, useMutation } from '@apollo/client';
import {
  CampaignCreationModalFragment,
  CreateCouponCampaignMutation,
  CreateCouponCampaignMutationVariables,
  GetCouponCampaignSummariesQuery,
} from 'graphql/types';
import { useForm } from 'react-hook-form';
import { Input } from 'components/input';
import { useMemo, useRef, useState } from 'react';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import clsx from 'clsx';
import { FaInfoCircle } from 'react-icons/fa';
import { Checkbox } from 'components/checkbox';
import { CreatableDropdown } from 'components/creatable-dropdown';
import { Dropdown, Option as DropdownOption } from 'components/dropdown';
import {
  combineRules,
  maxValueValidation,
  minValueValidation,
  positiveIntegerValidation,
  requiredValidation,
} from 'utils/form-validation';
import { TextArea } from 'components/text-area';

interface Props {
  loading: boolean;
  onClose: () => void;
  show: boolean;
  variants: CampaignCreationModalFragment['variants'];
  templates: CampaignCreationModalFragment['couponCampaignTemplates'];
}

export function CreateCampaignModal({
  templates,
  variants,
  show,
  onClose,
  loading,
}: Props) {
  const modalButtons = useRef<HTMLDivElement>(null);
  const [templateSearch, setTemplateSearch] = useState('');
  const [phase, setPhase] = useState<'templateSelect' | 'campaignCreation'>(
    'templateSelect',
  );

  const skuDropdownOptions = useMemo(
    () =>
      variants.reduce((acc: DropdownOption[], v) => {
        if (!v.inventory?.sku) {
          return acc;
        }

        if (!v.product.flexiCompatible) {
          return acc;
        }

        acc.push({
          label: v.product.name,
          value: v.inventory.sku,
        });

        return acc;
      }, []),
    [variants],
  );

  const form = useForm();

  const [createCouponCampaign, { loading: createCouponCampaignLoading }] =
    useMutation<
      CreateCouponCampaignMutation,
      CreateCouponCampaignMutationVariables
    >(gql`
      mutation CreateCouponCampaign($input: CreateCouponCampaignInput!) {
        createCouponCampaign(input: $input) {
          couponCampaign {
            id
          }
        }
      }
    `);

  const [selectedTemplate, setSelectedTemplate] = useState<
    | Exclude<
        CampaignCreationModalFragment['couponCampaignTemplates'],
        null | undefined
      >[number]
    | null
  >(null);

  const searchTemplates = useMemo(() => {
    if (!templates) {
      return [];
    }
    if (!templateSearch) {
      return templates;
    }
    return templates.filter((t) => {
      const searchSpace = t.displayName + t.description + t.instructions;
      return searchSpace.toLowerCase().includes(templateSearch.toLowerCase());
    });
  }, [templates, templateSearch]);

  return (
    <Modal show={show} onClose={onClose}>
      <section className="bg-white p-6 rounded space-y-3">
        <h2 className="heading-md mb-2">Create campaign</h2>

        {(() => {
          switch (phase) {
            case 'templateSelect':
              return (
                <>
                  <h3 className="mb-3">
                    Select a template as a base for your campaign.
                  </h3>
                  <Input
                    name="search"
                    placeholder="Search..."
                    onChange={(e) => setTemplateSearch(e.target.value)}
                  />

                  <label className="heading-sm block">
                    Results ({searchTemplates.length})
                  </label>

                  {searchTemplates.length === 0 && (
                    <p className="text-slate-500">
                      {loading ? 'Loading...' : 'No matching templates found'}
                    </p>
                  )}

                  {searchTemplates.map((t) => (
                    <div
                      key={t.id}
                      className={clsx(
                        'flex gap-3 justify-between cursor-pointer border rounded py-4 px-6',
                        {
                          'border-slate-200 border-2':
                            t.id !== selectedTemplate?.id,
                          'border-slate-900 border-2':
                            t.id === selectedTemplate?.id,
                        },
                      )}
                    >
                      <label
                        htmlFor={t.id}
                        className="flex w-full justify-between cursor-pointer space-y-1"
                      >
                        <div>
                          <p className="text-gray-800 text-lg font-semibold">
                            {t.displayName}
                          </p>
                          <p className="text-gray-600 text-md">
                            {t.description}
                          </p>
                          <p className="text-slate-500 text-xs">
                            {buildTemplateTags(t)}
                          </p>
                        </div>
                        <input
                          id={t.id}
                          ref={form.register({
                            required: 'Please select a template',
                          })}
                          value={t.id}
                          checked={selectedTemplate?.id === t.id}
                          onClick={() => {
                            setSelectedTemplate(t);
                            modalButtons.current?.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }}
                          type="radio"
                          className="cursor-pointer mt-1.5 ml-5 accent-primary"
                        />
                      </label>
                    </div>
                  ))}
                </>
              );

            case 'campaignCreation':
              return (
                <form>
                  <h3 className="mb-3">
                    <span className="font-medium">
                      {selectedTemplate?.displayName}
                      <br />
                    </span>
                    <span className="text-xs">
                      {selectedTemplate?.description}
                    </span>
                  </h3>
                  {selectedTemplate?.instructions && (
                    <div className="flex space-x-2 bg-blue-50 border rounded border-blue-600 px-4 py-2 text-gray-700 mb-6">
                      <FaInfoCircle className="mt-1 text-blue-700 " />
                      <div>
                        <p className="font-semibold">
                          How to use this template
                        </p>
                        <p>{selectedTemplate.instructions}</p>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col gap-2">
                    <Input
                      label="Campaign Name"
                      name="name"
                      placeholder="Enter campaign name"
                      ref={form.register({
                        required: 'Please provide campaign name',
                      })}
                      errorMessage={form.errors['name']?.message}
                    />
                    <TextArea
                      label="Description"
                      rows={3}
                      resize
                      name="description"
                      placeholder="Enter description"
                      ref={form.register({
                        required: 'Please provide description',
                      })}
                      errorMessage={form.errors['description']?.message}
                    />
                    {selectedTemplate?.parameters
                      ?.slice()
                      .sort((a, b) => {
                        // Move booleans to the end of the form so they present
                        // well as checkboxes.
                        return (
                          Number(
                            a.__typename ===
                              'CouponCampaignBooleanTemplateParameter',
                          ) -
                          Number(
                            b.__typename ===
                              'CouponCampaignBooleanTemplateParameter',
                          )
                        );
                      })
                      .map((p) => {
                        switch (p.__typename) {
                          case 'CouponCampaignFixedAmountTemplateParameter':
                            return (
                              <Input
                                key={p.id}
                                type="number"
                                ref={form.register(
                                  combineRules(
                                    requiredValidation(p.displayName),
                                    minValueValidation(p.displayName, 0),
                                    positiveIntegerValidation(p.displayName),
                                  ),
                                )}
                                label={p.displayName}
                                name={p.id}
                                placeholder={p.displayName}
                                errorMessage={form.errors[p.id]?.message}
                                tooltipProps={
                                  p.description
                                    ? { hoverText: p.description }
                                    : undefined
                                }
                              />
                            );
                          case 'CouponCampaignNumberTemplateParameter':
                            return (
                              <Input
                                key={p.id}
                                type="number"
                                ref={form.register(
                                  combineRules(
                                    requiredValidation(p.displayName),
                                    minValueValidation(p.displayName, 0),
                                  ),
                                )}
                                label={p.displayName}
                                name={p.id}
                                placeholder={p.displayName}
                                errorMessage={form.errors[p.id]?.message}
                                tooltipProps={
                                  p.description
                                    ? { hoverText: p.description }
                                    : undefined
                                }
                              />
                            );
                          case 'CouponCampaignStringTemplateParameter':
                            return (
                              <Input
                                key={p.id}
                                ref={form.register({
                                  required: `Please provide ${p.displayName}`,
                                })}
                                label={p.displayName}
                                name={p.id}
                                placeholder={p.displayName}
                                errorMessage={form.errors[p.id]?.message}
                                tooltipProps={
                                  p.description
                                    ? { hoverText: p.description }
                                    : undefined
                                }
                              />
                            );
                          case 'CouponCampaignBooleanTemplateParameter':
                            return (
                              <div key={p.id} className="mt-2">
                                <Checkbox
                                  ref={form.register()}
                                  label={p.displayName}
                                  description={p.description ?? undefined}
                                  name={p.id}
                                />
                              </div>
                            );
                          case 'CouponCampaignSkuListTemplateParameter':
                            return (
                              <Dropdown
                                isMulti
                                key={p.id}
                                label={p.displayName}
                                options={skuDropdownOptions}
                                name={p.id}
                                control={form.control}
                                errorMessage={form.errors[p.id]?.message}
                                tooltipProps={
                                  p.description
                                    ? { hoverText: p.description }
                                    : undefined
                                }
                              />
                            );
                          case 'CouponCampaignTimestampTemplateParameter':
                            return (
                              <Input
                                key={p.id}
                                type="datetime-local"
                                ref={form.register({
                                  required: `Please provide ${p.displayName}`,
                                })}
                                label={p.displayName}
                                name={p.id}
                                placeholder={p.displayName}
                                errorMessage={form.errors[p.id]?.message}
                                tooltipProps={
                                  p.description
                                    ? { hoverText: p.description }
                                    : undefined
                                }
                              />
                            );
                          case 'CouponCampaignPercentageTemplateParameter':
                            return (
                              <Input
                                key={p.id}
                                type="number"
                                ref={form.register(
                                  combineRules(
                                    requiredValidation(p.displayName),
                                    minValueValidation(p.displayName, 0),
                                    maxValueValidation(p.displayName, 100),
                                  ),
                                )}
                                label={p.displayName}
                                name={p.id}
                                placeholder={p.displayName}
                                errorMessage={form.errors[p.id]?.message}
                                tooltipProps={
                                  p.description
                                    ? { hoverText: p.description }
                                    : undefined
                                }
                              />
                            );
                          case 'CouponCampaignStringArrayTemplateParameter':
                            return (
                              <CreatableDropdown
                                key={p.id}
                                label={p.displayName}
                                options={[]}
                                name={p.id}
                                control={form.control}
                                errorMessage={form.errors[p.id]?.message}
                                tooltip={
                                  p.description
                                    ? { hoverText: p.description }
                                    : undefined
                                }
                              />
                            );
                          default:
                            throw new Error(`Unexpected parameter type: ${p}`);
                        }
                      })}
                  </div>
                </form>
              );
          }
        })()}

        <div ref={modalButtons} className="flex pt-4 gap-2 justify-end">
          <Button
            color="primary"
            variant="outline"
            onClick={() => {
              setPhase('templateSelect');
              if (phase === 'templateSelect') {
                setSelectedTemplate(null);
                onClose();
              }
            }}
          >
            {phase === 'campaignCreation' ? 'Back' : 'Cancel'}
          </Button>
          <Button
            color="primary"
            loading={
              loading ||
              createCouponCampaignLoading ||
              form.formState.isSubmitting
            }
            disabled={
              phase === 'templateSelect'
                ? !selectedTemplate?.id
                : form.formState.isSubmitting
            }
            onClick={async () => {
              if (phase === 'templateSelect') {
                setPhase('campaignCreation');
              }

              if (phase === 'campaignCreation') {
                await form.handleSubmit(async (data) => {
                  try {
                    await createCouponCampaign({
                      variables: {
                        input: {
                          name: data.name,
                          templateId: selectedTemplate?.id ?? '',
                          description: data.description,
                          parameters:
                            selectedTemplate?.parameters.map((p) => {
                              let type;
                              let value = data[p.id];
                              switch (p.__typename) {
                                case 'CouponCampaignBooleanTemplateParameter':
                                  type = 'BOOLEAN' as const;
                                  break;
                                case 'CouponCampaignFixedAmountTemplateParameter':
                                  type = 'FIXED_AMOUNT' as const;
                                  value = Number(value);
                                  break;
                                case 'CouponCampaignNumberTemplateParameter':
                                  type = 'NUMBER' as const;
                                  value = Number(value);
                                  break;
                                case 'CouponCampaignPercentageTemplateParameter':
                                  type = 'PERCENTAGE' as const;
                                  value = Number(value);
                                  break;
                                case 'CouponCampaignSkuListTemplateParameter':
                                  type = 'SKU_LIST' as const;
                                  break;
                                case 'CouponCampaignStringArrayTemplateParameter':
                                  type = 'STRING_ARRAY' as const;
                                  value = value.map((v: unknown) =>
                                    typeof v === 'object' &&
                                    v !== null &&
                                    'value' in v &&
                                    typeof v.value === 'string'
                                      ? v.value
                                      : '',
                                  );
                                  break;
                                case 'CouponCampaignStringTemplateParameter':
                                  type = 'STRING' as const;
                                  break;
                                case 'CouponCampaignTimestampTemplateParameter':
                                  type = 'TIMESTAMP' as const;
                                  value = new Date(value);
                                  break;
                                default:
                                  throw new Error(
                                    `Unexpected parameter type: ${p}`,
                                  );
                              }

                              return { id: p.id, value, type };
                            }) ?? [],
                        },
                      },
                    });
                    form.reset();
                    setPhase('templateSelect');
                    onClose();
                  } catch {
                    /* Caught by middleware */
                  }
                })();
              }
            }}
          >
            {phase === 'templateSelect' ? 'Next' : 'Create campaign'}
          </Button>
        </div>
      </section>
    </Modal>
  );
}

CreateCampaignModal.fragment = gql`
  fragment CampaignCreationModal on Query {
    variants {
      id
      product {
        id
        name
        flexiCompatible
      }
      inventory {
        id
        sku
      }
    }
    couponCampaignTemplates {
      id
      displayName
      stage
      strategy
      description
      instructions
      containsSkuTargeting
      containsConfigurableExpiry
      parameters {
        id
        displayName
        description
      }
    }
  }
`;

const buildTemplateTags = (
  t: Exclude<
    GetCouponCampaignSummariesQuery['couponCampaignTemplates'],
    null | undefined
  >[number],
): string => {
  const templateTags = [];
  templateTags.push(
    t.strategy.slice(0, 1).toUpperCase() + t.strategy.slice(1).toLowerCase(),
  );
  if (t.containsSkuTargeting) {
    templateTags.push('Targets SKUs');
  }
  templateTags.push(
    t.stage.slice(0, 1).toUpperCase() + t.stage.slice(1).toLowerCase(),
  );
  if (t.containsConfigurableExpiry) {
    templateTags.push('Expiry');
  }

  return templateTags.join(' • ');
};
