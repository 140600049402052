import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import {
  AssignPluripharmSkuMutation,
  AssignPluripharmSkuMutationVariables,
  CreateProductVariantInventoryMutation,
  CreateProductVariantInventoryMutationVariables,
  CreateProductVariantMutation,
  CreateProductVariantMutationVariables,
  DeleteProductVariantMutation,
  DeleteProductVariantMutationVariables,
  RemovePluripharmSkuMutation,
  RemovePluripharmSkuMutationVariables,
  SetAllOtcInactiveMutation,
  SetAllOtcInactiveMutationVariables,
  UpdateProductVariantInventoryMutation,
  UpdateProductVariantInventoryMutationVariables,
  UpdateProductVariantMutation,
  UpdateProductVariantMutationVariables,
  VariantFieldsFragment,
} from 'graphql/types';
import { Input } from '../../components/input';
import { Tag } from '../../components/tag';
import {
  maxLengthValidation,
  minLengthValidation,
  positiveIntegerValidation,
  requiredValidation,
} from '../../utils/form-validation';
import { Label } from '../../components/label';
import { Button } from '../../components/button';
import { config } from 'config';
import { Copyable } from '../../components/copyable';
import { FaTrashAlt } from 'react-icons/fa';
import { useNotifications } from 'notifications';
import { gql, useMutation } from '@apollo/client';
import { useAlert } from 'alert';

type FormVariant = {
  name: string;
  slug?: string;
  shopifyVariantId?: string;
  price: number;
  sku?: string;
  pluripharmSku?: string;
  public: boolean;
  isRefill: boolean;
};

export type VariantMode = {
  operation: 'create' | 'update';
} & (
  | {
      operation: 'create';
      onCreateCancel: () => void;
    }
  | {
      operation: 'update';
    }
);

export const Variant = ({
  variant,
  mode,
  orderLineItemsCount,
  onDelete,
  onCreate,
}: {
  orderLineItemsCount: number;
  variant: VariantFieldsFragment;
  mode: VariantMode;
  onCreate: () => Promise<void>;
  onDelete: () => Promise<void>;
}) => {
  const showAlert = useAlert();
  const showNotification = useNotifications();
  const [pluriSkuBeingConfigured, setPluriSkuBeingConfigured] =
    React.useState(false);
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    formState,
    trigger,
  } = useForm<FormVariant>({
    mode: 'onChange',
    defaultValues: {
      name: variant.name,
      slug: variant.slug ?? undefined,
      shopifyVariantId: variant.shopifyVariantId ?? undefined,
      price: variant.price,
      sku: variant.inventory?.sku ?? undefined,
      pluripharmSku: variant.pluripharmSku ?? undefined,
      public: variant.public,
      isRefill: variant.isRefill,
    },
  });

  // bit of a hack -> part of our system (EngineProducts) expects all variants to have a SKU
  // but historically this has not been true so many products now have variants without SKUs.
  // am highlighting this here to avoid ops asking why there is an error on load.
  useEffect(() => {
    trigger('sku');
  }, [trigger]);

  const [setAllOtcInactive] = useMutation<
    SetAllOtcInactiveMutation,
    SetAllOtcInactiveMutationVariables
  >(gql`
    mutation SetAllOtcInactive($productId: String!) {
      setAllOtcInactiveByProductId(productId: $productId)
    }
  `);

  const [updateProductVariant] = useMutation<
    UpdateProductVariantMutation,
    UpdateProductVariantMutationVariables
  >(gql`
    mutation UpdateProductVariant($variant: VariantUpdateInput!) {
      updateVariant(variant: $variant) {
        ...VariantFields
      }
    }
    ${Variant.variantFragment}
  `);

  const [createProductVariant] = useMutation<
    CreateProductVariantMutation,
    CreateProductVariantMutationVariables
  >(gql`
    mutation CreateProductVariant($variant: VariantWithSkuCreateInput!) {
      createVariantWithSku(variant: $variant) {
        ...VariantFields
      }
    }
    ${Variant.variantFragment}
  `);

  const [createVariantInventory] = useMutation<
    CreateProductVariantInventoryMutation,
    CreateProductVariantInventoryMutationVariables
  >(gql`
    mutation CreateProductVariantInventory(
      $variantInventory: VariantInventoryCreateInput!
    ) {
      createVariantInventory(variantInventory: $variantInventory) {
        id
      }
    }
  `);

  const [updateProductVariantInventory] = useMutation<
    UpdateProductVariantInventoryMutation,
    UpdateProductVariantInventoryMutationVariables
  >(gql`
    mutation UpdateProductVariantInventory(
      $variantInventory: VariantInventoryUpdateInput!
    ) {
      updateVariantInventory(variantInventory: $variantInventory) {
        id
        sku
      }
    }
  `);

  const [assignPluripharmSku] = useMutation<
    AssignPluripharmSkuMutation,
    AssignPluripharmSkuMutationVariables
  >(gql`
    mutation AssignPluripharmSku($input: AssignPluripharmSkuToVariantInput!) {
      assignPluripharmSkuToVariant(input: $input) {
        variant {
          id
          pluripharmSku
        }
      }
    }
  `);

  const [removePluripharmSku] = useMutation<
    RemovePluripharmSkuMutation,
    RemovePluripharmSkuMutationVariables
  >(gql`
    mutation RemovePluripharmSku($input: RemovePluripharmSkuFromVariantInput!) {
      removePluripharmSkuFromVariant(input: $input) {
        variant {
          id
        }
      }
    }
  `);

  const [deleteVariant, { loading: deleting }] = useMutation<
    DeleteProductVariantMutation,
    DeleteProductVariantMutationVariables
  >(gql`
    mutation DeleteProductVariant($id: String!) {
      deleteVariant(id: $id)
    }
  `);

  async function onSubmit(data: FormVariant) {
    if (mode.operation === 'create') {
      const d = await createProductVariant({
        variables: {
          variant: {
            productId: variant.product.id,
            name: data.name,
            slug: data.slug || undefined,
            sku: data.sku,
            price: data.price,
            public: !!data.public,
            isRefill: !!data.isRefill,
            shopifyVariantId: data.shopifyVariantId || undefined,
          },
        },
      });

      if (data.pluripharmSku && d.data?.createVariantWithSku?.id) {
        await assignPluripharmSku({
          variables: {
            input: {
              variantId: d.data?.createVariantWithSku?.id,
              sku: data.pluripharmSku,
            },
          },
        });
      }

      await onCreate();

      return;
    }

    // Legacy shenanigans:
    // We cannot change the price if there are orderLineItems that uses it.
    // Checking to see if we've changed the price compared to the original variants.
    if (orderLineItemsCount > 0 && data.price !== variant.price) {
      showNotification({
        type: 'error',
        message: `You can not update the price since it is being used`,
      });
      return;
    }

    // legacy hack, moved here from product update: https://github.com/eucalyptusvc/admins-ui/pull/248
    if (variant.public === true && data.public === false) {
      await setAllOtcInactive({
        variables: { productId: variant.product.id },
      });
    }

    // needs to happen first before we update the variant
    // as gql-admin expects variants to have a SKU
    if (data.sku && !variant.inventory?.id) {
      await createVariantInventory({
        variables: {
          variantInventory: {
            variantId: variant.id,
            sku: data.sku,
          },
        },
      });
    } else if (variant.inventory?.id) {
      await updateProductVariantInventory({
        variables: {
          variantInventory: {
            id: variant.inventory.id,
            sku: data.sku,
          },
        },
      });
    }

    await updateProductVariant({
      variables: {
        variant: {
          id: variant.id,
          productId: variant.product.id,
          name: data.name,
          slug: data.slug || undefined,
          price: data.price,
          public: !!data.public,
          isRefill: !!data.isRefill,
          shopifyVariantId: data.shopifyVariantId || undefined,
        },
      },
    });

    if (variant.pluripharmSku && !data.pluripharmSku) {
      await removePluripharmSku({
        variables: {
          input: {
            variantId: variant.id,
          },
        },
      });
    }

    if (data.pluripharmSku && data.pluripharmSku !== variant.pluripharmSku) {
      await assignPluripharmSku({
        variables: {
          input: {
            variantId: variant.id,
            sku: data.pluripharmSku,
          },
        },
      });
    }

    showNotification({
      message: `Updated variant`,
      type: 'success',
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <li className="space-y-5">
        <div className="space-y-5 bg-gray-100 rounded p-4">
          {variant.isRefill && (
            <div className="flex justify-end">
              <Tag size="small">Refill</Tag>
            </div>
          )}

          <div className="flex justify-end">
            <Copyable text={variant.id}>
              {(copied) => (
                <pre className="text-xs cursor-pointer text-stone-400">
                  Variant ID:&nbsp;
                  {copied ? (
                    'Copied'
                  ) : (
                    <span className="underline">{variant.id.slice(-6)}</span>
                  )}
                </pre>
              )}
            </Copyable>
          </div>
          <div>
            <Input
              label="Name"
              ref={register(requiredValidation('Name'))}
              name="name"
              errorMessage={errors.name?.message}
            />
          </div>
          <div>
            <Input
              label="Slug"
              ref={register()}
              name="slug"
              errorMessage={errors.slug?.message}
            />
          </div>
          {config.fulfilmentVendors.includes('shopify') && (
            <div>
              <Input
                label="Shopify Variant ID"
                ref={register()}
                name="shopifyVariantId"
                errorMessage={errors.shopifyVariantId?.message}
              />
            </div>
          )}
          <div>
            <Input
              label="Price (cents)"
              ref={register({
                ...requiredValidation('Price'),
                ...positiveIntegerValidation('price'),
                valueAsNumber: true,
              })}
              name="price"
              type="text"
              errorMessage={errors.price?.message}
            />
          </div>
          <div>
            <Input
              label="SKU"
              ref={register(requiredValidation('SKU'))}
              name="sku"
              errorMessage={errors.sku?.message}
            />
          </div>

          {(() => {
            if (!config.fulfilmentVendors.includes('pluripharm')) {
              return null;
            }

            if (!watch().pluripharmSku && !pluriSkuBeingConfigured) {
              return (
                <Button
                  variant="outline"
                  fullWidth
                  onClick={() => {
                    setPluriSkuBeingConfigured(true);
                  }}
                >
                  Add Pluripharm SKU
                </Button>
              );
            }

            return (
              <div className="flex gap-3">
                <div className="flex-1">
                  <Input
                    label="Pluripharm SKU"
                    type="number"
                    ref={register({
                      ...requiredValidation('PluripharmSku'),
                      ...minLengthValidation('PluripharmSku', 8),
                      ...maxLengthValidation('PluripharmSku', 8),
                    })}
                    name="pluripharmSku"
                    errorMessage={errors.pluripharmSku?.message}
                  />
                </div>
                <div className="mt-[28px] flex items-center">
                  <button
                    className="h-8 w-8 flex items-center justify-center cursor-pointer shadow-sm bg-red-500 hover:bg-red-600 rounded-full text-white"
                    onClick={() => {
                      setPluriSkuBeingConfigured(false);
                      setValue('pluripharmSku', '');
                    }}
                  >
                    <FaTrashAlt size={14} />
                  </button>
                </div>
              </div>
            );
          })()}

          {variant.product.productType === 'ACCESSORY' ? (
            <div className="w-1/2 flex gap-4 items-center">
              <input
                type="checkbox"
                ref={register()}
                name="public"
                id="public"
                value="public"
                defaultChecked={variant.public}
              />
              <Label htmlFor="public">Visible to patients</Label>
            </div>
          ) : (
            <div className="flex">
              <div className="w-1/2 flex gap-4 items-center">
                <input
                  type="checkbox"
                  ref={register()}
                  name="public"
                  id={`public`}
                  value="public"
                />
                <Label htmlFor={`public`}>Public</Label>
              </div>
              <div className="w-1/2 flex gap-4 items-center">
                <input
                  type="checkbox"
                  ref={register()}
                  name="isRefill"
                  id={`isRefill`}
                  value="isRefill"
                />
                <Label htmlFor={`isRefill`}>Is refill</Label>
              </div>
            </div>
          )}

          {mode.operation === 'create' ? (
            <div className="flex items-center justify-between">
              <div className="w-32">
                <Button
                  fullWidth
                  type="button"
                  color="danger"
                  variant="outline"
                  onClick={mode.onCreateCancel}
                >
                  Cancel
                </Button>
              </div>
              <div className="w-32">
                <Button fullWidth type="submit" color="primary">
                  Create
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <div className="w-32">
                <Button
                  fullWidth
                  type="button"
                  color="danger"
                  disabled={formState.isSubmitting}
                  loading={deleting}
                  variant="outline"
                  onClick={async () => {
                    const result = await showAlert({
                      content: 'Are you sure you want to delete this variant?',
                    });

                    if (!result) {
                      return;
                    }

                    await deleteVariant({
                      variables: { id: variant.id },
                    });

                    showNotification({
                      message: `Deleted variant`,
                      type: 'success',
                    });

                    await onDelete();
                  }}
                >
                  Delete
                </Button>
              </div>
              <div className="w-32">
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  disabled={!formState.isDirty || deleting}
                  loading={formState.isSubmitting}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      </li>
    </form>
  );
};

Variant.variantFragment = gql`
  fragment VariantFields on Variant {
    id
    name
    slug
    price
    public
    shopifyVariantId
    isRefill
    pluripharmSku
    product {
      id
      productType
    }
    inventory {
      id
      sku
    }
  }
`;
